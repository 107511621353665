export const possibleLLMCoreRunTags = [
  "interactive-testing",
  "workflow",
  "chat",
  "tool-3par",
  "tool-link",
  "tool-ai-actions",
  "chrome-extension",
  "incorporated-feedback",
  "new-workflow-agent",
] as const;

// todo: work with pod3 to make this dynamic
export const SOURCE_API_TO_HUMAN_READABLE = {
  AirtableCLIAPI: "Airtable",
  GoogleSheetsV2API: "Google Sheets",
  GoogleDocsV2API: "Google Docs",
  GoogleDocsV2CLIAPI: "Google Docs",
  NotionCLIAPI: "Notion",
  TableCLIAPI: "Zapier Tables",
  HubSpotCLIAPI: "HubSpot",
  ZendeskV2CLIAPI: "Zendesk",
  LinkFileUpload: "Uploaded Files",
};

export * from "./chats-list";
