import { ReactNode, createContext, useContext, useState } from "react";

export enum TourPoint {
  ExtensionOnDemandBehaviors = "extensionOnDemandBehaviors",
}

interface TourPointsContext {
  tourPoints: TourPoint[];
  dismissTourPoint: (tourPoint: TourPoint) => void;
  isTourPointActive: (tourPoint: TourPoint) => boolean;
}

const TourPointsContext = createContext<TourPointsContext>({
  tourPoints: [],
  dismissTourPoint: () => {},
  isTourPointActive: () => false,
});

export const useTourPoints = () => {
  return useContext(TourPointsContext);
};

interface TourPointsProviderProps {
  children: ReactNode;
}

export const TourPointsProvider = ({ children }: TourPointsProviderProps) => {
  const [tourPoints, setTourPoints] = useState(
    Object.values(TourPoint).filter((key) => {
      const savedValue =
        typeof window !== "undefined"
          ? window.localStorage.getItem(`${key}TooltipDismissed`)
          : undefined;
      return savedValue !== "true";
    }),
  );

  const dismissTourPoint = (key: TourPoint) => {
    setTourPoints(tourPoints.filter((tourPoint) => tourPoint !== key));
    window.localStorage.setItem(`${key}TooltipDismissed`, "true");
  };

  const isTourPointActive = (key: TourPoint) => {
    return tourPoints[0] === key;
  };

  return (
    <TourPointsContext.Provider
      value={{ tourPoints, dismissTourPoint, isTourPointActive }}
    >
      {children}
    </TourPointsContext.Provider>
  );
};
