import type { Prisma } from "@prisma/client";
import { z } from "zod";

import type {
  Bot,
  BotShare,
  Workflow,
} from "@zapai/database/src/generated/zod";

import { WorkflowControl } from "./workflow";

export { Bot, BotShare };

export enum BotType {
  CHAT,
  DATA,
  COMMAND,
  BACKGROUND,
}

export type BotWithActions = Bot & { actions?: WorkflowControl[] };

export type BotWithWorkflows = Bot & { workflows?: Workflow[] };

const gridRow = z.tuple([z.number(), z.number(), z.number(), z.number()]);

export const botMetadataSchemaBody = z.object({
  avatar: z
    .object({
      grid: z.tuple([gridRow, gridRow, gridRow, gridRow]),
      color: z.string(),
    })
    .optional(),
});

export type BotMetadata = z.infer<typeof botMetadataSchemaBody>;

export type BotToCopy = Prisma.BotGetPayload<{
  select: {
    id: true;
    name: true;
    ownerMembership: {
      select: {
        user: {
          select: {
            name: true;
          };
        };
      };
    };
    description: true;
    instructions: true;
    connectedIntegrationIds: true;
    metadata: true;
    actions: {
      where: {
        workflowId: null;
      };
      select: {
        name: true;
        displayName: true;
        description: true;
        selectedApi: true;
        actionKey: true;
        actionType: true;
        appNeedsAuth: true;
        needs: true;
      };
    };
    workflows: {
      select: {
        name: true;
        connectedIntegrationIds: true;
        data: true;
        type: true;
        instructions: true;
        triggerSuggestionsIgnored: true;
        actionSuggestionsIgnored: true;
        trigger: {
          select: {
            name: true;
            displayName: true;
            description: true;
            selectedApi: true;
            actionKey: true;
            actionType: true;
            appNeedsAuth: true;
            needs: true;
          };
        };
        actions: {
          select: {
            id: true;
            name: true;
            displayName: true;
            description: true;
            selectedApi: true;
            actionKey: true;
            actionType: true;
            appNeedsAuth: true;
            needs: true;
          };
        };
      };
    };
  };
}>;
