import { QueryKey } from "@tanstack/react-query";

/** Query key for a bot's workflows */
export const queryKeyForBotWorkflows = (botId: string) =>
  ["workflows", botId] as const;

/**
 * Note that this is NOT the cache key for chat **messages**, only for the chat entity itself.
 * If you want the cache key for chat messages, use `queryKeyForChatMessages` instead.
 */
export const queryKeyForChat = (chatId: string): QueryKey => ["chats", chatId];

/**
 * Weird behavior alert!
 *
 * We store threads and chats the same way in the cache, but with different keys.
 * If a parent message ID is included here, then the stored values are for a thread and not a chat.
 * If the parent message ID is `null`, then the stored values are for a chat.
 *
 * @param chatId - ID of the actual chat
 * @param parentMessageId - ID of the parent message. If supplied, then the query is for a thread and not a chat.
 * @returns Key to use for react-query for a specific chat.
 */
export const queryKeyForChatMessages = (
  chatId: string,
  parentMessageId?: string | null,
): QueryKey => ["chat-messages", chatId, parentMessageId ?? undefined];

export const queryKeyForChatMessage = (messageId: string): QueryKey => [
  "chat-message",
  messageId,
];

export const queryKeyForWorkflowTemplate = ({
  workflowTemplateId,
}: {
  workflowTemplateId?: string | null;
}): QueryKey => [
  "workflow-template",
  { workflowTemplateId: workflowTemplateId ?? undefined },
];
