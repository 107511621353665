import { z } from "zod";

/**
 * Base schema used for all streaming events
 */
export const StreamEventDataBaseSchema = z.object({
  type: z.string(),
  payload: z.any(),
});

export type StreamEventDataBase = z.infer<typeof StreamEventDataBaseSchema>;

export function isStreamEventDataBase(
  eventData: any,
): eventData is StreamEventDataBase {
  return (
    eventData &&
    typeof eventData.type === "string" &&
    eventData.payload !== undefined
  );
}
