"use client";

import { Spinner as SpinnerFromDesignSystem } from "@zapier/design-system";
import { ReactNode, forwardRef } from "react";

import { ReplaceReturnType } from "@/helpers";
import { useTheme } from "@/hooks/use-theme";

// temp solution for lack of React 19 support
const SpinnerWithNodeReturn = SpinnerFromDesignSystem as ReplaceReturnType<
  typeof SpinnerFromDesignSystem,
  ReactNode
>;

interface SpinnerProps {
  ariaLabel?: string;
  theme?: "light" | "dark";
  size?: "small" | "medium" | "large";
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  (props, ref) => {
    const { theme: appTheme } = useTheme();

    return (
      <SpinnerWithNodeReturn
        color={props.theme ?? (appTheme === "dark" ? "light" : "dark")}
        {...props}
      />
    );
  },
);

Spinner.displayName = "Spinner";
