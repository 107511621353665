import { z } from "zod";

import { ChatType } from "../chat";
import { StreamEventDataBaseSchema, isStreamEventDataBase } from "./base";

// --- chat.created ---
export const ChatCreatedEventSchema = StreamEventDataBaseSchema.extend({
  type: z.literal("chat.created"),
  payload: z.object({
    botId: z.string(),
    chatId: z.string(),
    chatType: z.nativeEnum(ChatType).optional(),
  }),
});

export type ChatCreatedEvent = z.infer<typeof ChatCreatedEventSchema>;

export function isChatCreatedEvent(
  eventData: any,
): eventData is ChatCreatedEvent {
  return isStreamEventDataBase(eventData) && eventData.type === "chat.created";
}
