export enum UIEvent {
  OpenWorkflowsDialog = "openWorkflowsDialog",
  FillChatInput = "fillChatInput",
  FillChatImage = "fillChatImage",
  FillInstructionsInput = "fillInstructionsInput",
  OpenDatasourceConfiguration = "openDatasourceConfiguration",
  OpenDatasourcesPopover = "openDatasourcesPopover",
  OpenActionsPopover = "openActionsPopover",
  OpenWorkflowsPopover = "openWorkflowsPopover",
  InteractiveTestWorkflowEnded = "interactiveTestWorkflowEnded",
}

// Add the payload types for each event here, and it'll automatically typecheck the
// payload you pass to dispatchCustomUIEvent based on the eventName you pass to it.
// If you pass a non-existing eventName, it'll throw a type error.
interface UIEventPayloads {
  [UIEvent.OpenWorkflowsDialog]: {
    workflowId: string;
  };
  [UIEvent.FillChatInput]: {
    message: string;
    append?: boolean;
  };
  [UIEvent.FillInstructionsInput]: {
    message: string;
    append?: boolean;
  };
  [UIEvent.FillChatImage]: {
    imageUri: string;
  };
  [UIEvent.OpenDatasourceConfiguration]: {
    datasourceId: string;
  };
  [UIEvent.OpenDatasourcesPopover]: {};
  [UIEvent.OpenActionsPopover]: {};
  [UIEvent.OpenWorkflowsPopover]: {};
  [UIEvent.InteractiveTestWorkflowEnded]: {
    testChatId: string;
  };
}

export type CustomUIEvent<T extends UIEvent> = CustomEvent<UIEventPayloads[T]>;

export const dispatchCustomUIEvent = <T extends UIEvent>(
  eventName: T,
  payload?: UIEventPayloads[T],
) => {
  window.dispatchEvent(
    new CustomEvent<UIEventPayloads[T]>(eventName, { detail: payload }),
  );
};
