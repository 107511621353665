import type { Prisma } from "@prisma/client";

import type { MessageFeedbackCategoryGroupType } from "@zapai/database/src/generated/zod";
import { MessageFeedbackCategoryGroupSchema } from "@zapai/database/src/generated/zod";

export type MessageFeedbackCategoryGroup = MessageFeedbackCategoryGroupType;
export const MessageFeedbackCategoryGroup =
  MessageFeedbackCategoryGroupSchema.enum;

export type {
  MessageFeedback,
  MessageFeedbackCategory,
} from "@zapai/database/src/generated/zod";

export type MessageFeedbackWithMessageBlocks =
  Prisma.MessageFeedbackGetPayload<{
    include: {
      user: true;
      category: true;
      message: {
        include: {
          blocks: {
            orderBy: {
              createdDT: "asc";
            };
          };
          bot: true;
        };
      };
    };
  }>;
