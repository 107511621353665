import { z } from "zod";

import type { WorkflowExecution } from "@zapai/database/src/generated/zod";

import {
  ChatCompletionToolMessageBlock,
  WorkflowTriggerEventBlock,
} from "./message-blocks";

export { WorkflowExecution };

/**
 * This is the specific type that's store in the `details` JSON column in the WorkflowExecution table
 */
export const workflowExecutionDetailsSchema = z
  .object({
    chatId: z.string(),
    messageId: z.string().optional(),
    statusMessage: z.string().optional(),
    finalSubAgentState: z.string().optional(),
    responseMessageId: z.string().optional(),
    deployedContext: z.string().optional(),
  })
  .optional();

export type WorkflowExecutionDetails = z.infer<
  typeof workflowExecutionDetailsSchema
>;

export interface WorkflowExecutionWithActivity extends WorkflowExecution {
  activity: {
    actionsUsed?: ChatCompletionToolMessageBlock[];
    triggerUsed?: WorkflowTriggerEventBlock;
  };
}
