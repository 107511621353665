import { ReactNode, useContext, useState } from "react";

import { RawBlocksContext } from "@/context/raw-blocks";

export const useRawBlocks = () => {
  return useContext(RawBlocksContext);
};

interface RawBlocksProviderProps {
  children: ReactNode;
}

export const RawBlocksProvider = ({ children }: RawBlocksProviderProps) => {
  const [showRawBlocks, setShowRawBlocks] = useState(false);

  return (
    <RawBlocksContext.Provider value={{ showRawBlocks, setShowRawBlocks }}>
      {children}
    </RawBlocksContext.Provider>
  );
};
