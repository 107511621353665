import type { Prisma } from "@prisma/client";
import {
  ChatCompletionAssistantMessageParam,
  ChatCompletionFunctionMessageParam,
  ChatCompletionMessageToolCall,
  ChatCompletionSystemMessageParam,
  ChatCompletionToolMessageParam,
  ChatCompletionUserMessageParam,
} from "openai/resources";

import type {
  MessageBlock,
  MessageBlockTypeType,
  WorkflowExecutionStatusType,
  WorkflowExecutionTypeType,
} from "@zapai/database/src/generated/zod";
import { MessageBlockTypeSchema } from "@zapai/database/src/generated/zod";

import { WorkflowControl, WorkflowWithLiftedData } from "./workflow";

export { MessageBlock };

export type MessageBlockType = MessageBlockTypeType;
export const MessageBlockType = MessageBlockTypeSchema.enum;

interface MessageBlockWithoutData extends Omit<MessageBlock, "data"> {}

export type MessageWithBlocks = Prisma.MessageGetPayload<{
  include: { blocks: true };
}>;

export type PromptLevel = "welcome" | "primary" | "suggestion";

export interface CodeBlock extends MessageBlock {
  type: "CodeStream";
  data: {
    code: string;
    output?: string;
    startDT?: string;
    finishedDT?: string;
  };
}

export interface InteractiveElementBase extends Prisma.JsonObject {
  type: string;
  fieldId: string;
  isDisabled?: boolean;
}

/**
 * NOTE: This should more or less match the `SearchSelectOption` type.
 */
export interface OptionObject extends Prisma.JsonObject {
  text: string;
  value: string;
  userPrompt?: string;
  promptLevel?: PromptLevel;
  description?: string;
  leadingIcon?: {
    iconUrl?: string;
    altText: string;
    iconName?: string;
  };
  disabled?: boolean;
}
export interface OptionGroupObject extends Prisma.JsonObject {
  label: string;
  options: OptionObject[];
}

export interface ElementButton extends InteractiveElementBase {
  type: "button";
  text: string;
  userPrompt: string;
  promptLevel?: PromptLevel;
  value: string;
  style?: "primary" | "danger";
  leadingIcon?: {
    iconUrl?: string;
    altText: string;
    iconName?: string;
  };
}

export interface ElementRadioButtonGroup extends InteractiveElementBase {
  type: "radio_button_group";
  options: OptionObject[];
}

export interface ElementSelectMenu extends InteractiveElementBase {
  type: "static_select";
  options?: OptionObject[];
  option_groups?: OptionGroupObject[];
}

export interface ElementSearchSelectMenu extends InteractiveElementBase {
  type: "searchable_select";
  options?: OptionObject[];
  leadingIcon?: {
    iconUrl?: string;
    altText: string;
    iconName?: string;
  };
}

export function isRadioButtonGroup(
  field: InteractiveElementBase,
): field is ElementRadioButtonGroup {
  return field && field.type === "radio_button_group";
}

export function isSelectMenuField(
  field: InteractiveElementBase,
): field is ElementSelectMenu {
  return field && field.type === "static_select";
}

export function isSearchSelectMenuField(
  field: InteractiveElementBase,
): field is ElementSearchSelectMenu {
  return field && field.type === "searchable_select";
}

export function isButtonField(
  field: InteractiveElementBase,
): field is ElementButton {
  return field && field.type === "button";
}

export type InteractiveElement =
  | ElementButton
  | ElementRadioButtonGroup
  | ElementSelectMenu
  | ElementSearchSelectMenu;

export type ElementFieldValue = null | string | string[];
export interface InteractiveElementsBlock extends MessageBlock {
  type: "Interactive";
  data: {
    completedDT?: string;
    cancelledDT?: string;
    expirationDT?: string;
    values: Record<string, ElementFieldValue>;
    elements: InteractiveElement[];
  };
}

export function isInteractiveElementsBlock(
  obj: any,
): obj is InteractiveElementsBlock {
  return Boolean(obj && obj.type === "Interactive");
}

export interface ChatProgressBlock extends MessageBlock {
  type: "ChatProgress";
  data: {
    role: "assistant";
    content: string;
    metadata?: any;
  };
}

// typeguard for ChatProgressBlock
export function isChatProgressBlock(obj: any): obj is ChatProgressBlock {
  return Boolean(obj && obj.type === "ChatProgress");
}

export enum ToolStatus {
  success = "success",
  error = "error",
  preview = "preview",
  cancelled = "cancelled",
  in_progress = "in_progress",
}

export type ActionUsedMetadata = Pick<
  WorkflowControl,
  "id" | "actionKey" | "actionType" | "displayName" | "selectedApi"
>;

export type AIActionsResolvedParams = Record<
  string,
  {
    name: string;
    label: string | null;
    value: string | Array<Record<string, any>> | Record<string, any>;
  }
>;

export interface ChatCompletionSystemMessageBlock
  extends MessageBlockWithoutData {
  type: "ChatCompletion";
  data: ChatCompletionSystemMessageParam;
}

export interface ChatCompletionUserMessageBlock
  extends MessageBlockWithoutData {
  type: "ChatCompletion";
  // FIXME(vitorbal): support `ChatCompletionContentPart[]` format
  data: ChatCompletionUserMessageParam & {
    content: string;
    metadata: {
      // image requests
      image_url?: string;
      quotes?: {
        sourceContent?: string;
        generatedText?: string;
        integrationId?: string;
        sourceUrl?: string;
      }[];
    };
  };
}

export function isChatCompletionUserMessageBlock(
  obj: any,
): obj is ChatCompletionUserMessageBlock {
  return Boolean(
    obj && obj.type === "ChatCompletion" && obj.data.role === "user",
  );
}

export interface ChatCompletionAssistantMessageBlock
  extends MessageBlockWithoutData {
  type: "ChatCompletion";
  data: ChatCompletionAssistantMessageParam & {
    debug?: any;
    metadata?: {
      /**
       * Describes a tool_calls request that the user must approve before the assistant can continue.
       * Note: approval is for all tools at once. E.g. If one is rejected, all are rejected.
       */
      tool_calls_request?: {
        status: "pending" | "approved" | "rejected";
        /**
         * The ID of the workflow execution that this tool_calls approval request is part of.
         * If this is not part of a workflow execution (i.e. sync chat), this field will be undefined.
         */
        workflowExecutionId?: string;
        /**
         * If the status is `rejected`, this field will optionally contain the feedback provided by the user.
         */
        rejectionFeedback?: string;
        /**
         * Tracks AI Action preview calls that were made as part of this tool_calls approval request (i.e calling AI actions with `"preview_only": true`).
         */
        toolCallPreviews: Array<
          | {
              status: "loading";
              /** The ID of the tool call. */
              id: string;
              action_used: ActionUsedMetadata;
            }
          | {
              status: "success";
              /** The ID of the tool call. */
              id: string;
              action_used: ActionUsedMetadata;
              result: {
                input_params: Record<string, any>;
                resolved_params: AIActionsResolvedParams;
              };
            }
          | {
              status: "error";
              /** The ID of the tool call. */
              id: string;
              action_used: ActionUsedMetadata;
              result: {
                errorMessage: string;
                input_params?: Record<string, any>;
                resolved_params?: AIActionsResolvedParams;
              };
            }
        >;
      };

      citations?: {
        sourceContent?: string;
        generatedText?: string;
        integrationId?: string;
      }[];

      /**
       * Result of the `predictNextStep` call.
       */
      next_step_prediction?: {
        reasoning: string;
        user_facing_message: string;
        next_step: string;
      };

      next_step_metadata?: {
        progress_message: string;
        action_used?: ActionUsedMetadata;
      };

      /**
       * Result of the `calculateNextStepParams` call.
       */
      next_step_params?: {
        reasoning: string;
        tool_parameters: Record<string, unknown>;
      };
    };
  };
}

export function isChatCompletionAssistantMessageBlock(
  obj: any,
): obj is ChatCompletionAssistantMessageBlock {
  return Boolean(
    obj && obj.type === "ChatCompletion" && obj.data.role === "assistant",
  );
}

export type AssistantMessageBlockWithPendingToolCalls = Omit<
  ChatCompletionAssistantMessageBlock,
  "data"
> & {
  data: Omit<
    ChatCompletionAssistantMessageBlock["data"],
    "tool_calls" | "metadata"
  > & {
    tool_calls: ChatCompletionMessageToolCall[];
    metadata: Omit<
      ChatCompletionAssistantMessageBlock["data"]["metadata"],
      "tool_calls_request"
    > & {
      tool_calls_request: NonNullable<
        NonNullable<
          ChatCompletionAssistantMessageBlock["data"]["metadata"]
        >["tool_calls_request"]
      >;
    };
  };
};

export function isChatCompletionAssistantMessageBlockWithToolCallsRequest(
  obj: any,
): obj is AssistantMessageBlockWithPendingToolCalls {
  return Boolean(
    isChatCompletionAssistantMessageBlock(obj) &&
      obj.data.tool_calls &&
      obj.data.metadata?.tool_calls_request,
  );
}

export interface ChatCompletionAssistantMessageBlockWithToolCalls
  extends MessageBlockWithoutData {
  type: "ChatCompletion";
  data: ChatCompletionAssistantMessageParam & {
    tool_calls: Array<ChatCompletionMessageToolCall>;
  };
}

export function isChatCompletionAssistantMessageBlockWithToolCalls(
  obj: any,
): obj is ChatCompletionAssistantMessageBlockWithToolCalls {
  return Boolean(
    obj &&
      obj.type === "ChatCompletion" &&
      Boolean(obj.data.tool_calls?.length),
  );
}

export interface AiQueryMetadata {
  // prints the final tool answer below blocks
  finalAnswer?: {
    content: string;
    streaming: boolean;
  };
  plan?: {
    status: ToolStatus;
    cot?: string;
    question: string;
    steps?: {
      id: number;
      // deprecated, we now use `stepType`
      node_type?: string;
      stepType?: string;
      question: string;
    }[];
    error?: string;
    failFast?: string;
  };
  steps?: {
    // deprecated, we now use `stepType`
    node_type?: "CODE" | "SQL" | "SQL+SEMANTIC";
    stepType?: "CODE" | "SQL" | "SQL+SEMANTIC";
    id: number;
    question: string;
    code?: string;
    cot?: string;
    results?: Record<string, any>[];
    status: ToolStatus;
    error?: string;
    failFast?: string;
  }[];
  quotes?: {
    responsePart: string;
    integrationId: string;
    sourceContent: string;
  }[];
}

// TODO(corbin): Type this as unions of hardcoded names and corresponding metadata
// instead of having everything optionally present that can be present.
export interface ChatCompletionToolMessageBlock
  extends MessageBlockWithoutData {
  type: "ChatCompletion";
  data: ChatCompletionToolMessageParam & {
    name: string;
    debug?: any;
    metadata: {
      status: ToolStatus;
      errorMetadata?: any;

      /**
       * @deprecated Use `status` instead
       */
      tool_execution_succeed?: boolean;

      // AI Actions
      action_used?: ActionUsedMetadata;

      input_params?: Record<string, any>;
      resolved_params?: AIActionsResolvedParams;

      // incorporateFeedback
      feedback?: string;
      botId?: string;

      // useExternalDataSourceToAnswerQuestion - a.k.a. AI Query
      // legacy - we no longer put code on the top-level
      code?: string;
      // legacy - we no longer put results on the top-level
      results?: Record<string, any>[];
      // Current AI Query fields
      aiQuery?: AiQueryMetadata;
    };
  };
}

export function isChatCompletionToolMessageBlock(
  obj: any,
): obj is ChatCompletionToolMessageBlock {
  return Boolean(
    obj && obj.type === "ChatCompletion" && obj.data.role === "tool",
  );
}

// For backwards compatibility with old message blocks, we need to support function messages (even though we deprecated them in favor of tool messages)
interface ChatCompletionFunctionMessageBlock extends MessageBlockWithoutData {
  type: "ChatCompletion";
  data: ChatCompletionFunctionMessageParam & {
    metadata: ChatCompletionToolMessageBlock["data"]["metadata"];
  };
}

export type ChatCompletionBlock =
  | ChatCompletionSystemMessageBlock
  | ChatCompletionUserMessageBlock
  | ChatCompletionAssistantMessageBlock
  | ChatCompletionToolMessageBlock
  | ChatCompletionFunctionMessageBlock;

// typeguard for ChatCompletionBlock
export function isChatCompletionBlock(obj: any): obj is ChatCompletionBlock {
  return Boolean(obj && obj.type === "ChatCompletion");
}

/** A workflow trigger event that caused a bot workflow to start. Will be processed and turned into a chat completion by the LLM Core. */
export interface WorkflowTriggerEventBlock extends MessageBlock {
  type: "WorkflowTriggerEvent";
  data: {
    rawPayload: Record<string, any>;
    processedPayload: Record<string, any>;
    workflowId: WorkflowWithLiftedData["id"];
    triggerData: {
      id: string;
      displayName: string;
      selectedApi: string;
      actionKey: string;
    };
  };
}

export function isWorkflowTriggerEventBlock(
  obj: any,
): obj is WorkflowTriggerEventBlock {
  return Boolean(obj && obj.type === "WorkflowTriggerEvent");
}

export interface ThreeParReadTableBlock extends MessageBlock {
  type: "ThreeParReadTable";
  data: {
    // TODO: add data structure for three par read table response
  };
}

// typeguard for ThreeParReadTableBlock
export function isThreeParReadTableBlock(
  obj: any,
): obj is ThreeParReadTableBlock {
  return Boolean(obj && obj.type === "ThreeParReadTable");
}

export interface ThreeParReadListBlock extends MessageBlock {
  type: "ThreeParReadList";
  data: {
    // TODO: add data structure for three par read list response
  };
}

// typeguard for ThreeParReadListBlock
export function isThreeParReadListBlock(
  obj: any,
): obj is ThreeParReadListBlock {
  return Boolean(obj && obj.type === "ThreeParReadList");
}

export interface ThreeParReadEntityBlock extends MessageBlock {
  type: "ThreeParReadEntity";
  data: {
    // TODO: add data structure for three par read entity response
  };
}

// typeguard for ThreeParReadEntityBlock
export function isThreeParReadEntityBlock(
  obj: any,
): obj is ThreeParReadEntityBlock {
  return Boolean(obj && obj.type === "ThreeParReadEntity");
}

export interface DatasetEventBlock extends MessageBlock {
  type: "DatasetEvent";
  data: {
    /**
     * Contains metadata about a dataset event that the UI should know about
     */
    datasetEvent: {
      type: "created" | "updated";
      /** The dataset ID */
      id: string;
      /** The column names */
      columns: string[];
      /* A sample row of data */
      sampleRow: any;
    };
  };
}

// typeguard for DatasetEventBlock
export function isDatasetEventBlock(obj: any): obj is DatasetEventBlock {
  return Boolean(obj && obj.type === "DatasetEvent");
}

export interface WorkflowExecutionRunBlock extends MessageBlock {
  type: "WorkflowExecutionRun";
  data: {
    role: "user" | "assistant";
    content: string;
    workflowName: string;
    workflowId: string;
    executionStatus: WorkflowExecutionStatusType;
    executionType: WorkflowExecutionTypeType;
  };
}

// typeguard for WorkflowExecutionRunBlock
export function isWorkflowExecutionRunBlock(
  obj: any,
): obj is WorkflowExecutionRunBlock {
  return Boolean(obj && obj.type === "WorkflowExecutionRun");
}

export interface WorkflowExecutionReferenceBlock extends MessageBlock {
  type: "WorkflowExecutionReference";
  data: {
    role: "assistant";
    content: string;
    workflowName: string;
    workflowExecutionCreatedDT: string;
    workflowExecutionId: string;
    workflowExecutionStatus: WorkflowExecutionStatusType;
    workflowExecutionDetails: {
      chatId: string;
      messageId: string;
    };
  };
}

// typeguard for WorkflowExecutionReferenceBlock
export function isWorkflowExecutionReferenceBlock(
  obj: any,
): obj is WorkflowExecutionReferenceBlock {
  return Boolean(obj && obj.type === "WorkflowExecutionReference");
}

export interface OnboardingBlock extends MessageBlock {
  type: "Onboarding";
  data: {
    role: "assistant";
    isFirstChat: string;
    fromTemplateId?: string;
    botId?: string;
  };
}

// typeguard for OnboardingBlock
export function isOnboardingBlock(obj: any): obj is OnboardingBlock {
  return Boolean(obj && obj.type === "Onboarding");
}

export interface BehaviorSuggestionBlock extends MessageBlock {
  type: "BehaviorSuggestion";
  data: {
    role: "assistant";
    botId: string;
    behavior: {
      instructions: string;
      instructionsInNounForm?: string;
    };
  };
}

// typeguard for BehaviorSuggestionBlock
export function isBehaviorSuggestionBlock(
  obj: any,
): obj is BehaviorSuggestionBlock {
  return Boolean(obj && obj.type === "BehaviorSuggestion");
}

/**
 * A "workflow ended" event that can happen during an interactive test.
 * Will be rendered as a CTA to the user asking for feedback on how the test went.
 **/
export interface InteractiveTestWorkflowEndedBlock extends MessageBlock {
  type: "InteractiveTestWorkflowEnded";
  data: {
    status: "done" | "no_action_needed";
  };
}

// typeguard for InteractiveTestWorkflowEndedBlock
export function isInteractiveTestWorkflowEndedBlock(
  obj: any,
): obj is InteractiveTestWorkflowEndedBlock {
  return Boolean(obj && obj.type === "InteractiveTestWorkflowEnded");
}
