import { QueryClient } from "@tanstack/react-query";

import {
  StreamEventData,
  isChatMessageBlockUpdatedEvent,
  isChatMessageUpdatedEvent,
} from "@zapai/client-sdk";

import { handleMessageBlockUpdatedEvent } from "./chat-message-block-updated-event";
import { handleChatMessageUpdatedEvent } from "./chat-message-updated-event";

/**
 * This will handle the streaming data coming from the server and update the react-query cache.
 *
 * NOTE: The data sent to the cache MUST be a wholly new object, or react-query won't re-render the component.
 * (TODO we might want to look into using something like immer here?)
 *
 * @param queryClient - react-query client used for updating cached data
 * @param data - The message coming in
 */
export const handleChatStreamingMessage = (
  queryClient: QueryClient,
  data: StreamEventData,
) => {
  if (isChatMessageUpdatedEvent(data)) {
    // message data is being updated
    // note that sometimes, this update will come before/instead of the message being created
    handleChatMessageUpdatedEvent(queryClient, data);
  } else if (isChatMessageBlockUpdatedEvent(data)) {
    // a specific block is being updated in the message
    // for streaming messages (i.e. the chatbot "typing") this will be sent multiple times
    handleMessageBlockUpdatedEvent(queryClient, data);
  }
};
