import type { Prisma } from "@prisma/client";
import { Action } from "@zapier/ai-actions";
import { z } from "zod";

import type {
  Workflow,
  WorkflowTemplate,
} from "@zapai/database/src/generated/zod";
import type {
  WorkflowExecutionStatusType,
  WorkflowExecutionTypeType,
  WorkflowTypeType,
} from "@zapai/database/src/generated/zod";
import {
  WorkflowExecutionStatusSchema,
  WorkflowExecutionTypeSchema,
  WorkflowTypeSchema,
} from "@zapai/database/src/generated/zod";

export { Workflow, WorkflowTemplate };

export type WorkflowType = WorkflowTypeType;
export const WorkflowType = WorkflowTypeSchema.enum;
export type WorkflowExecutionType = WorkflowExecutionTypeType;
export const WorkflowExecutionType = WorkflowExecutionTypeSchema.enum;
export type WorkflowExecutionStatus = WorkflowExecutionStatusType;
export const WorkflowExecutionStatus = WorkflowExecutionStatusSchema.enum;

const workflowControlNeedValue = z.union([
  z.string(),
  z.number(),
  z.array(z.string()),
  z.record(z.string(), z.string()),
  z.null(),
]);

export type WorkflowControlNeedValue = z.infer<typeof workflowControlNeedValue>;

const workflowControlLabel = z.union([z.string(), z.array(z.string())]);

export type WorkflowControlLabel = z.infer<typeof workflowControlLabel>;

export const workflowControlNeedsSchema = z
  .record(
    z.object({
      value: workflowControlNeedValue,

      /** This is shown in dropdowns if we haven't fetched data yet */
      label: workflowControlLabel.optional(),

      /** This won't exist for needs added prior to 05/15/24, but should exist after that */
      type: z.string().optional(),

      /**
       * The label for the need itself.
       * For example, if this is a "custom need", this will be the actual field name.
       */
      needLabel: z.string().optional(),
      helpText: z.union([z.string().optional(), z.null()]),
      customField: z.boolean().optional(),
      altersCustomFields: z.union([z.boolean().optional(), z.null()]),
      /** This won't exist prior to 05/14/24, but should exist after that */
      required: z.boolean().optional(),
    }),
  )
  .refine(
    (data) => {
      // Check if both `value` and `label` are either both strings or both arrays
      const isValueArray = Array.isArray(data.value);
      const isLabelArray = Array.isArray(data.label);
      return (isValueArray && isLabelArray) || (!isValueArray && !isLabelArray);
    },
    {
      message: "Both `value` and `label` should be either strings or arrays",
      path: ["value", "label"],
    },
  );

export type WorkflowControlNeeds = z.infer<typeof workflowControlNeedsSchema>;

export type WorkflowControlNeed = WorkflowControlNeeds[string];

export const workflowAppSchema = z.object({
  selected_api: z.string(),
  name: z.string(),
  logo_url: z.string().optional(),
  auth_type: z.string().optional(),
});

export type WorkflowApp = z.infer<typeof workflowAppSchema>;

export const workflowControlAuthSchema = z.object({
  id: z.number().optional(),
  accountId: z.number(),
  customuserId: z.number(),
  title: z.string().optional(),
});

export type WorkflowControlAuth = z.infer<typeof workflowControlAuthSchema>;

export const connectedIntegrationIdsSchema = z.array(z.string());

// TODO: after migration, we can just use the Action type directly
/** Represents a trigger that has been selected for a workflow */
export const workflowControlSchema = z.object({
  id: z.string(),
  selectedApi: z.string(),
  actionKey: z.string(),
  actionType: z.string(),

  // we keep the display name stored so that we can show it as soon as the UI renders
  // otherwise, we'd have to make an API call to get this info
  // (we'd still have to make one for logo url, but we might have it from selectedApi)
  displayName: z.string(),
  description: z.string(),

  needs: workflowControlNeedsSchema.optional(),

  // Shouldn't ever actually allow null, but we need this for now because of a temporary issue
  // where null values were stored in prisma.
  auth: workflowControlAuthSchema.optional().nullable(),
  appNeedsAuth: z.boolean().optional().default(true),
  workflowId: z.string().optional().nullable(),

  // todo: after data migration, make these not optional
  userId: z.string().optional(),
  botId: z.string().optional(),

  lastUsedDT: z.date().optional().nullable(),
});

export type WorkflowControl = z.infer<typeof workflowControlSchema>;

/**
 * Used by the Instant Actions endpoints
 */
export const workflowControlArraySchema = z.array(workflowControlSchema);

/**
 * A string potentially containing pill directives from our frontend text editor UI.
 * To parse the pill directives, use the `replacePillDirectivesWithNames` function.
 */
export const maybeStringWithPillDirectivesSchema = z
  .string()
  .brand<"MaybeStringWithPillDirectives">();
export type MaybeStringWithPillDirectives = z.infer<
  typeof maybeStringWithPillDirectivesSchema
>;

/**
 * This is the specific type that's store in the `data` JSON column
 * in the Workflow table
 */
export const workflowDataSchema = z.object({
  instructions: maybeStringWithPillDirectivesSchema,
  trigger: workflowControlSchema.optional().nullable(),
  actions: z.array(workflowControlSchema).optional(),
  triggerSuggestionsIgnored: z.boolean().default(false).optional(),
  actionSuggestionsIgnored: z.boolean().default(false).optional(),
});

export type WorkflowData = z.infer<typeof workflowDataSchema>;

/**
 * Schema used to create a new workflow for a bot
 */
export const workflowSchemaWithLiftedData = z.object({
  name: z.string(),
  enabled: z.boolean().default(false).optional(),
  triggerSubscriptionId: z.string().optional().nullable(),
  instructions: maybeStringWithPillDirectivesSchema,
  triggerId: z.string().optional().nullable(),
  trigger: workflowControlSchema.optional().nullable(),
  actions: z.array(workflowControlSchema).optional(),
  connectedIntegrationIds: connectedIntegrationIdsSchema.optional(),
  triggerSuggestionsIgnored: z.boolean().default(false).optional(),
  actionSuggestionsIgnored: z.boolean().default(false).optional(),
  fromWorkflowTemplateId: z.string().optional().nullable(),
});

export type WorkflowSchemaWithLiftedData = z.infer<
  typeof workflowSchemaWithLiftedData
>;

type ObjectWithWorkflowData = {
  data: WorkflowData;
};

/**
 * Workflow type from Prisma with specific data type
 */
export type WorkflowWithData = Omit<Workflow, "data"> & ObjectWithWorkflowData;

export type WorkflowWithLiftedData = Omit<Workflow, "data"> & WorkflowData;

type WorkflowWithBotUser = Prisma.WorkflowGetPayload<{
  include: {
    bot: {
      include: {
        ownerMembership: {
          include: {
            user: true;
          };
        };
      };
    };
  };
}>;

export type WorkflowWithBotUserAndLiftedData = Omit<
  WorkflowWithBotUser,
  "data"
> &
  WorkflowData;

export type MinimalWorkflow = Prisma.WorkflowGetPayload<{
  select: {
    triggerSubscriptionId: true;
    botId: true;
    id: true;
    type: true;
  };
}>;

/** This is a special action key used for the trigger of on-demand workflows */
export const ON_DEMAND_WORKFLOW_ACTION_KEY = "zapier_bot_dm_trigger";

export const ON_DEMAND_TRIGGER: Action = {
  app: "",
  type: "read",
  action: ON_DEMAND_WORKFLOW_ACTION_KEY,
  display_name: "On demand",
  description: "Start the behavior when the user decides to.",
  search_relevancy_score: 1,
  app_info: {
    app: ON_DEMAND_WORKFLOW_ACTION_KEY,
    name: "On demand",
    logo_url: "",
    auth_type: "none",
    actions: {
      read: 1,
      read_bulk: 1,
      write: 1,
      search: 1,
      search_or_write: 1,
      search_and_write: 1,
      filter: 1,
    },
  },
  app_needs_auth: false,
};

/**
 * Type of control for a workflow.
 * trigger: "read" type of AI action
 * action: "write" or "search" type of AI Action
 */
export type WorkflowControlType = "trigger" | "action";

const workflowTemplateControlSchema = workflowControlSchema.extend({
  customDescription: z.string().optional(),
  customRequiredNeeds: z.record(z.string(), z.string()).optional(),
});

export const workflowTemplateDataSchema = z.object({
  instructions: maybeStringWithPillDirectivesSchema.optional(),
  schemaVersion: z.string().optional(),
  trigger: workflowTemplateControlSchema,
  actions: z.array(workflowTemplateControlSchema).optional(),
  connectedIntegrationIds: z.array(z.string()).optional().default([]),
});

export type WorkflowTemplateData = z.infer<typeof workflowTemplateDataSchema>;

export type WorkflowTemplateControl = z.infer<
  typeof workflowTemplateControlSchema
>;

export type WorkflowTemplateWithParsedData = WorkflowTemplate & {
  data: WorkflowTemplateData;
};

export interface WorkflowToCreate
  extends Pick<
    WorkflowWithData,
    | "name"
    | "type"
    | "instructions"
    | "triggerSuggestionsIgnored"
    | "actionSuggestionsIgnored"
    | "fromWorkflowTemplateId"
    | "connectedIntegrationIds"
  > {
  trigger: WorkflowWithData["data"]["trigger"];
  actions: WorkflowWithData["data"]["actions"];
}
