import {
  Icon as IconFromDesignSystem,
  IconName,
  IconProps,
} from "@zapier/design-system";
import { ReactNode } from "react";

import { ReplaceReturnType } from "@/helpers";

// temp solution for lack of React 19 support
const Icon = IconFromDesignSystem as ReplaceReturnType<
  typeof IconFromDesignSystem,
  ReactNode
>;

export { Icon };
export type { IconProps, IconName };
