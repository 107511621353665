import {
  ChatCreatedEvent,
  StreamEventData,
  WorkflowWithLiftedData,
} from "@zapai/client-sdk";

import type { Integration } from "@/hooks/three-par";

/**
 * This is used by the ably auth route to grant permissions to the channelIdForChatPresence channels.
 *
 * The reason we wildcard instead of granting based on chats the user has access to is twofold:
 * 1. Ably has a hard request body size limit, and many workflow chats can blow up this limit, breaking ably auth
 * 2. We don't have to reauthorize ably every time a new chat gets created
 */
export const channelIdForWildcardChatPresence = "chat:*:presence";

/**
 * This is used by the frontend to register presence to Ably for a specific chat the user is looking at.
 *
 * Note: this is *only* used for presence, it's not used for subscribing to events or publishing events.
 * @param chatId - ID of chat to register presence for the user
 * @returns channel id to use when registering chat presence to Ably
 */
export const channelIdForChatPresence = (chatId: string) =>
  `chat:${chatId}:presence`;

/**
 * This is used to subscribe to / publish events for a specific user account.
 * It's primarily used for chat events, but we may use it for other things in the future.
 *
 * The process for publishing events for a chat is basically:
 * 1. Get the ably chat presence channel
 * - `const channel = ablyClient.channels.get(channelIdForChatPresence(chatId));`
 * 2. Get the membershipIds of users currently looking at the chat
 * - `const membershipIds = (await channel.presence.get()).items.map((item) => item.data.membershipId);`
 * 3. Pull the ChatUserMemberships attached to the chat
 * - `const chatUserMemberships = await prisma.chatUserMembership.findMany({where: {chatId}});`
 * 4. Filter the ChatUserMemberships based on if the chatUserMembership.membershipId exists in the presence membershipIds array
 * 5. Publish the event to the ably channel for each present membership
 * - channel will be `channelIdForUserMembership(membershipId)`
 * - use `broadcastChannelEvent` to publish the event
 * Note: there will usually only be at most one ChatUserMembership per chat.
 *
 * Note: Technically, steps 1 and 2are optional, we don't *have* to filter for only present users, but it's
 * better practice to only send the ably events if there's someone to receive them on the other side.
 *
 * @param membershipId - the current membershipId for the user (`session.user.currentMembershipId`)
 *
 * Note: membershipId changes with the account switcher, userId/zapierCustomUserId does not
 *
 * @returns channel id to use when subscribing to membership-level events
 */
export const channelIdForUserMembership = (membershipId: string) =>
  `membership:${membershipId}`;
/**
 * Primarily used for Link datasource integration status updates
 *
 * @param currentAccountId - the current zapierAccountId for the user
 * @param zapierCustomUserId - the zapierCustomUserId for the user
 * @returns channel id to use when subscribing to non-shared integration events
 */
export const channelIdForUserIntegration = (
  currentAccountId: number,
  zapierCustomUserId: number,
) => `integration:account:${currentAccountId}:customuser:${zapierCustomUserId}`;
/**
 * This isn't really used right now, but Link does have capabilities to dispatch it.
 */
export const channelIdForSharedIntegration = (currentAccountId: number) =>
  `integration:account:${currentAccountId}`;
/**
 * This is used to subscribe to bot events, particularly workflow updates (including trigger subscriptions)
 *
 * @param botId - the botId to subscribe to
 * @returns channel id to use when subscribing to bot events
 */
export const channelIdForBot = (botId: string) => `bot:${botId}`;

export enum AblyEventName {
  /** Streaming chat message, sent to membership channel */
  StreamingEvent = "STREAMING_EVENT",

  /** Sent from Link when a data source integration has an updated status, sent to integration channel */
  IntegrationStatusUpdate = "THREE_PAR_INTEGRATION_UPDATE",

  /** Sent when a workflow updates, including trigger subscription success or failure, sent to bot channel */
  WorkflowUpdate = "WORKFLOW_UPDATE",

  /** New chat created (for background behaviors for example), sent to membership channel */
  ChatCreated = "CHAT_CREATED",
}

/**
 * Mapping of event name to event data type
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- We want a type here and not an interface
export type AblyEventType = {
  [AblyEventName.StreamingEvent]: StreamEventData;
  [AblyEventName.IntegrationStatusUpdate]: Integration;
  [AblyEventName.WorkflowUpdate]: WorkflowWithLiftedData;
  // This is technically already covered by the StreamEventData, but we want to dispatch them to ably
  // as a separate event to keep channel subscriptions / callbacks easier to manage.
  [AblyEventName.ChatCreated]: ChatCreatedEvent;
};
