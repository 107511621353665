import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

import { MinimalChatData } from "@zapai/client-sdk";

export const queryKeyForBotChats = (botId: string) => ["bot-chats", botId];

export const queryKeyForFilteredBotChats = (
  botId: string,
  chatsOnly?: boolean,
) => ["bot-chats", botId, chatsOnly ?? false];

/**
 * A hook that retrieves the PAGINATED chats for a specified bot.
 *
 * @param botId - The unique identifier of the bot whose chats are to be fetched.
 * @param chatsOnly - Whether to only show chats (and not behavior runs).
 * @param initialData - Optional. Initial data to be used during the query.
 *
 * @returns The query result containing the chat history data.
 */
export const useBotChatsInfiniteQuery = ({
  botId,
  chatsOnly,
  initialData,
}: {
  botId: string;
  chatsOnly?: boolean;
  initialData?: { data: MinimalChatData[]; nextCursor?: string };
}) => {
  return useInfiniteQuery({
    queryKey: queryKeyForFilteredBotChats(botId, chatsOnly),
    queryFn: async ({ pageParam }) => {
      const response = await axios.get<{
        data: MinimalChatData[];
        nextCursor: string;
      }>(
        `/api/bots/${botId}/conversations?${
          pageParam ? `&cursor=${pageParam as string}` : ""
        }${chatsOnly ? `&chatsOnly=true` : ""}`,
      );
      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextCursor;
    },
    refetchOnMount: false,
    initialData:
      initialData && !chatsOnly
        ? {
            pages: [initialData],
            pageParams: [undefined],
          }
        : undefined,
  });
};
