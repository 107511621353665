import { QueryClient } from "@tanstack/react-query";

import {
  ChatMessageQueryData,
  ChatMessageUpdatedEvent,
  FullChatMessage,
} from "@zapai/client-sdk";

import {
  queryKeyForChatMessage,
  queryKeyForChatMessages,
} from "@/hooks/helpers/query-keys";

const getParentMessageWithUpdatedCount = (
  originalParentMessage: FullChatMessage,
  data: ChatMessageUpdatedEvent,
) => {
  const parentMessage = { ...originalParentMessage };
  // Get a new reference
  parentMessage._count = {
    childMessages: parentMessage._count.childMessages,
  };

  // if parent message doesn't already contain this message as a child
  if (
    !parentMessage.childMessages.find(
      (message) => message.id === data.payload.message.id,
    )
  ) {
    parentMessage._count.childMessages++;
    parentMessage.childMessages = [
      ...parentMessage.childMessages,
      {
        id: data.payload.message.id,
        bot: data.payload.message.botId
          ? {
              id: data.payload.message.botId,
            }
          : null,
        membership:
          data.payload.message.membershipId && data.payload.userId
            ? {
                user: {
                  id: data.payload.userId,
                },
              }
            : null,
      },
    ];
  }

  return parentMessage;
};

/**
 * Called whenever a new message in a thread comes through.
 * This will add a new placeholder message to the end of the thread,
 * and increment the number of messages in the thread.
 *
 * NOTE: This updates the ROOT chat, not the thread itself.
 * In the root thread, we only track the last message in the thread and the number of messages in it.
 * This also updates the root message (used by workflow execution reference thread and behavior activity panel)
 */
const handleNewMessageInThread = (
  queryClient: QueryClient,
  data: ChatMessageUpdatedEvent,
) => {
  const { chatId, parentMessageId } = data.payload;
  if (!parentMessageId) {
    return;
  }
  queryClient.setQueryData<ChatMessageQueryData>(
    queryKeyForChatMessages(chatId), // ROOT chat query key, not for the thread!!!
    (messages) => {
      if (!messages) {
        return;
      }

      const parentMessageIndex = messages.findIndex(
        (message) => message.id === parentMessageId,
      );

      return parentMessageIndex !== -1
        ? [
            ...messages.slice(0, parentMessageIndex),
            getParentMessageWithUpdatedCount(
              messages[parentMessageIndex],
              data,
            ),
            ...messages.slice(parentMessageIndex + 1),
          ]
        : undefined;
    },
  );
  // update workflow execution messages and workflow reference
  queryClient.setQueryData<FullChatMessage>(
    queryKeyForChatMessage(parentMessageId), // ROOT message query key, container of the threaded messages!!!
    (message) => {
      return message
        ? getParentMessageWithUpdatedCount(message, data)
        : undefined;
    },
  );
};

/**
 * Handle a `message.updated` event.
 *
 * This is more of an "upsert" event and will insert or update a message in the cache.
 */
export const handleChatMessageUpdatedEvent = (
  queryClient: QueryClient,
  data: ChatMessageUpdatedEvent,
) => {
  const { chatId, parentMessageId } = data.payload;

  queryClient.setQueryData<ChatMessageQueryData>(
    queryKeyForChatMessages(chatId, parentMessageId),
    (messages) => {
      if (parentMessageId) {
        // if we have a parent message ID, then we want to also update the ROOT chat
        // for this message; this will update the count of messages in the thread
        // and add a new placeholder message for the last child message,
        // which will then accept message blocks going forward
        handleNewMessageInThread(queryClient, data);
      }

      // If the query cache isn't initialized yet, return just this message
      if (!messages) {
        return [createMessageFromMessagePayload(data.payload.message)];
      }

      const existingIndex = messages.findLastIndex(
        (message) => message.id === data.payload.message.id,
      );

      if (existingIndex === -1) {
        // this is a new message, add it to the list
        return [
          ...messages,
          createMessageFromMessagePayload(data.payload.message),
        ];
      }

      return [
        ...messages.slice(0, existingIndex),
        {
          // keep existing fields
          ...messages[existingIndex],
          // update fields that are present
          ...data.payload.message,
        },
        ...messages.slice(existingIndex + 1),
      ];
    },
  );
};

/**
 * Create a new message from the message payload.
 * This is used when the message from the payload doesn't already exist in the query cache.
 */
const createMessageFromMessagePayload = (
  messagePayload: ChatMessageUpdatedEvent["payload"]["message"],
) => ({
  blocks: [],
  userFeedbacks: [],
  childMessages: [],
  _count: { childMessages: 0 },
  ...messagePayload,
});
