import { z } from "zod";

import { MessageBlockSchema } from "@zapai/database/src/generated/zod";

import { StreamEventDataBaseSchema, isStreamEventDataBase } from "./base";

// --- messageBlock.updated ---
export const ChatMessageBlockUpdatedEventSchema =
  StreamEventDataBaseSchema.extend({
    type: z.literal("messageBlock.updated"),
    payload: z.object({
      block: MessageBlockSchema,
      blockId: z.string(),
      messageId: z.string(),
      chatId: z.string(),
      parentMessageId: z.string().nullish(),
    }),
  });

export type ChatMessageBlockUpdatedEvent = z.infer<
  typeof ChatMessageBlockUpdatedEventSchema
>;

export function isChatMessageBlockUpdatedEvent(
  eventData: any,
): eventData is ChatMessageBlockUpdatedEvent {
  return (
    isStreamEventDataBase(eventData) &&
    eventData.type === "messageBlock.updated"
  );
}
