import type { Prisma } from "@prisma/client";

import type { TemplateVisibilityType } from "@zapai/database/src/generated/zod";
import { TemplateVisibilitySchema } from "@zapai/database/src/generated/zod";

import { WorkflowTemplateWithParsedData } from "./workflow";

export type TemplateVisibility = TemplateVisibilityType;
export const TemplateVisibility = TemplateVisibilitySchema.enum;

export type FullBotTemplate = Prisma.BotTemplateGetPayload<{
  include: {
    actionTemplates: true;
    botTemplateGroup: true;
    workflowTemplates: true;
    datasourceTemplates: true;
    user: {
      select: {
        name: true;
        email: true;
      };
    };
  };
}>;

export interface FullBotTemplateWithParsedWorkflowTemplates
  extends FullBotTemplate {
  workflowTemplates: WorkflowTemplateWithParsedData[];
}
