import type { SubscriptionStatusTypeType } from "@zapai/database/src/generated/zod";
import { SubscriptionStatusTypeSchema } from "@zapai/database/src/generated/zod";

import { ZapierEntitlementsResponse } from "./auth";

export type SubscriptionStatusType = SubscriptionStatusTypeType;
export const SubscriptionStatusType = SubscriptionStatusTypeSchema.enum;

export interface BillingPlan {
  limits: Pick<
    ZapierEntitlementsResponse["limits"],
    | "central_activity_executions_limit"
    | "central_is_web_browsing_enabled"
    | "central_is_google_search_enabled"
    | "central_agent_max_executions"
    | "add_ons"
  >;
  // So far, I've only witnessed "month", so I'm guessing on "year" and other.
  interval: "month" | "year" | string;
  periodStart: string;
  periodEnd: string;
}

export enum CentralPlanName {
  Free = "central-free",
  Premium = "central-premium-v1",
  Advanced = "central-advanced-v1",
}

export interface BillingFeatureConfig {
  isWebBrowsingEnabled: boolean;
  isGoogleSearchEnabled: boolean;
  agentMaxExecutions: number;
}

export interface BillingUsageResponse {
  planName: CentralPlanName;
  activities: BillingUsageItem;
  periodStart: string;
  periodEnd: string;
}

export interface BillingUsageItem {
  limit: number | "unlimited";
  count: number;
  percent: number;
}
