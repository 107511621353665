import { QueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";

import {
  ChatCompletionToolMessageBlock,
  ChatMessageQueryData,
  FullChatMessage,
  MAX_CONVERSATIONS_PER_PAGE,
  MessageBlock,
  MessageBlockType,
  MinimalChatData,
  isChatCompletionToolMessageBlock,
  uuidv4,
} from "@zapai/client-sdk";

import {
  queryKeyForBotChats,
  queryKeyForFilteredBotChats,
} from "@/hooks/use-bot-chats-query";

import {
  queryKeyForBotWorkflows,
  queryKeyForChat,
  queryKeyForChatMessage,
  queryKeyForChatMessages,
} from "./query-keys";

export function updateOrAddMessageBlocksToMessage({
  chatId,
  messageId,
  parentMessageId,
  queryClient,
  blocksToUpdate,
}: {
  chatId: string;
  messageId: string;
  parentMessageId?: string | null;
  queryClient: QueryClient;
  blocksToUpdate: MessageBlock[];
}) {
  queryClient.setQueryData<ChatMessageQueryData>(
    queryKeyForChatMessages(chatId, parentMessageId),
    (messages) => {
      if (!messages) {
        return;
      }

      const messageIndexToUpdate = messages.findIndex(
        (message) => message.id === messageId,
      );

      if (messageIndexToUpdate === -1) {
        return messages;
      }

      const updatedMessage = updateOrAddMessageBlocks(
        messages[messageIndexToUpdate],
        blocksToUpdate,
      );

      return [
        ...messages.slice(0, messageIndexToUpdate),
        updatedMessage,
        ...messages.slice(messageIndexToUpdate + 1),
      ];
    },
  );

  if (!parentMessageId) {
    queryClient.setQueryData<FullChatMessage>(
      queryKeyForChatMessage(messageId),
      (message) => {
        if (!message) {
          return;
        }

        const newMessage = { ...message };
        return updateOrAddMessageBlocks(newMessage, blocksToUpdate);
      },
    );
  }

  // if there's been an incorporate feedback tool call, we invalidate the bot workflows
  // this way, the workflow is re-fetched with the updated instructions
  // Refetch workflow when instructions are updated
  const botIdToRefetch = blocksToUpdate.find(
    (block): block is MessageBlock & ChatCompletionToolMessageBlock =>
      isChatCompletionToolMessageBlock(block) &&
      ["incorporateFeedback", "incorporateUserFeedback"].includes(
        block.data?.name,
      ),
  )?.data?.metadata?.botId;

  if (botIdToRefetch) {
    queryClient.invalidateQueries({
      queryKey: queryKeyForBotWorkflows(botIdToRefetch),
    });
  }
}

/**
 * @param message - Message to add/update block to
 * @param blocksToUpdate - Blocks to add/update
 * @returns NEW object with the block added/updated
 */
const updateOrAddMessageBlocks = (
  message: FullChatMessage,
  blocksToUpdate: MessageBlock[],
): FullChatMessage => {
  const updatedBlocks = [...(message.blocks || [])];

  blocksToUpdate.forEach((block) => {
    const blockIndex = updatedBlocks.findIndex(
      (existing) => existing.id === block.id,
    );

    if (blockIndex === -1) {
      updatedBlocks.push(block);
    } else {
      updatedBlocks[blockIndex] = block;
    }
  });

  return { ...message, blocks: updatedBlocks };
};

export function addOptimisticUserMessage({
  chatId,
  message,
  membershipId,
  parentMessageId,
  queryClient,
}: {
  chatId: string;
  message: string;
  membershipId: string;
  parentMessageId?: string | null;
  queryClient: QueryClient;
}) {
  const messageId = uuidv4();
  const blockId = uuidv4();

  const userMessage = {
    id: messageId,
    chatId,
    parentMessageId: parentMessageId ?? null,
    botId: null,
    membershipId,
    sentDT: new Date(),
    createdDT: new Date(),
    updatedDT: new Date(),
    _count: { childMessages: 0 },
    blocks: [
      {
        id: blockId,
        chatId,
        messageId,
        type: MessageBlockType.ChatCompletion,
        data: {
          role: "user",
          content: message,
        },
        createdDT: new Date(),
        updatedDT: new Date(),
      },
    ],
    userFeedbacks: [],
    childMessages: [],
    stateMachineExecutionId: null,
  };
  queryClient.setQueryData<ChatMessageQueryData>(
    queryKeyForChatMessages(chatId, parentMessageId),
    (messages) => {
      // Messages are only empty here when replying to a closed thread
      if (!messages) {
        // If it's not a thread reply on a closed thread, skip
        if (!parentMessageId) {
          return;
        }
        return [userMessage];
      }

      return [...messages, userMessage];
    },
  );

  return userMessage;
}

// this will take a flat list of chats, sort them, and generate the correct cache shape for bot chats
// it will just generate a single page with all the chat results currently in the cache
export const createUpdatedChatsList = (chats: MinimalChatData[]) => {
  const sortedChats = orderBy(
    chats.map((chat) => ({
      ...chat,
      createdDT: new Date(chat.createdDT),
    })),
    [
      ({ chatUserMemberships }) => chatUserMemberships?.[0]?.isStarred,
      "createdDT",
    ],
    ["desc", "desc"],
  );

  const lastChat = sortedChats[sortedChats.length - 1];

  return {
    pages: [
      {
        data: sortedChats,
        nextCursor:
          // todo(corbin): this might cause some problems if a user has multiple pages
          // of chats, but hasn't loaded more yet, then deletes a chat. it would
          // prevent the user from being able to load more chats until they switch bots
          // or refresh the page.
          // The reason it's here now is to prevent show more from showing when the user
          // deletes a chat (or createUpdatedChatsList is called) while the user has fewer
          // chats than the page size.
          sortedChats.length < MAX_CONVERSATIONS_PER_PAGE
            ? undefined
            : lastChat?.id,
      },
    ],
    pageParams: [undefined],
  };
};

export const upsertChatToCache = ({
  chat,
  botId,
  queryClient,
  chatsOnly,
}: {
  chat: MinimalChatData;
  botId: string;
  queryClient: QueryClient;
  chatsOnly?: boolean;
}) => {
  // Add chat to chat cache
  queryClient.setQueryData<MinimalChatData>(queryKeyForChat(chat.id), chat);

  // Add chat to chats list cache
  upsertChatToChatsListCache({
    chat,
    queryKey:
      chatsOnly === undefined
        ? queryKeyForBotChats(botId)
        : queryKeyForFilteredBotChats(botId, chatsOnly),
    queryClient,
  });
};

const upsertChatToChatsListCache = ({
  chat,
  queryKey,
  queryClient,
}: {
  chat: MinimalChatData;
  queryKey: unknown[];
  queryClient: QueryClient;
}) => {
  queryClient.setQueriesData<{
    pages: { data: MinimalChatData[]; nextCursor?: string }[];
  }>(
    {
      queryKey,
    },
    (cachedData) => {
      const chats = cachedData?.pages.flatMap((page) => page.data) ?? [];

      const indexToUpdate = chats.findIndex((c) => c.id === chat.id);

      if (indexToUpdate > -1) {
        chats[indexToUpdate] = chat;
      } else {
        chats.push(chat);
      }

      return createUpdatedChatsList(chats);
    },
  );
};
