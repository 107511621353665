import { z } from "zod";

export enum DirectiveType {
  Datasource = "datasource",
  Action = "action",
}

export const datasourceDirectiveMetadataSchema = z.object({
  type: z.literal("datasource"),
  id: z.string(),
  label: z.string(),
  sourceApi: z.string(),
});

export const actionDirectiveMetadataSchema = z.object({
  type: z.literal("action"),
  id: z.string(),
  label: z.string(),
  selectedApi: z.string(),

  // These two shouldn't actually be present, but used to be present,
  // so we're keeping them to not bomb the safeParse for now.
  appNeedsAuth: z.string().optional(),
  authId: z.string().optional(),
});

export const directiveMetadataSchema = z.union([
  datasourceDirectiveMetadataSchema,
  actionDirectiveMetadataSchema,
]);

export type DirectiveMetadataSchema = z.infer<typeof directiveMetadataSchema>;
