import { z } from "zod";

import { MessageSchema } from "@zapai/database/src/generated/zod";

import { StreamEventDataBaseSchema, isStreamEventDataBase } from "./base";

// --- chatMessage.updated ---
export const ChatMessageUpdatedEventSchema = StreamEventDataBaseSchema.extend({
  type: z.literal("chatMessage.updated"),
  payload: z.object({
    message: MessageSchema,
    chatId: z.string(),
    parentMessageId: z.string().nullish(),
    userId: z.string().optional(),
  }),
});

export type ChatMessageUpdatedEvent = z.infer<
  typeof ChatMessageUpdatedEventSchema
>;

export function isChatMessageUpdatedEvent(
  eventData: any,
): eventData is ChatMessageUpdatedEvent {
  return (
    isStreamEventDataBase(eventData) && eventData.type === "chatMessage.updated"
  );
}
