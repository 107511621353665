import { z } from "zod";

import { ChatCreatedEventSchema } from "./chat";
import { ChatMessageUpdatedEventSchema } from "./chat-message";
import { ChatMessageBlockUpdatedEventSchema } from "./message-block";

export { isChatMessageUpdatedEvent } from "./chat-message";
export { isChatMessageBlockUpdatedEvent } from "./message-block";
export { isChatCreatedEvent } from "./chat";

export type { ChatMessageUpdatedEvent } from "./chat-message";
export type { ChatMessageBlockUpdatedEvent } from "./message-block";
export type { ChatCreatedEvent } from "./chat";
/**
 * Union of all streaming events
 */
export const StreamEventDataSchema = z.union([
  ChatMessageUpdatedEventSchema,
  ChatMessageBlockUpdatedEventSchema,
  ChatCreatedEventSchema,
]);

export type StreamEventData = z.infer<typeof StreamEventDataSchema>;
