import type { Prisma } from "@prisma/client";

import { Chat, ChatTypeType } from "@zapai/database/src/generated/zod";
import { ChatTypeSchema } from "@zapai/database/src/generated/zod";

export type { Chat };

export type ChatType = ChatTypeType;
export const ChatType = ChatTypeSchema.enum;

/** The minimal set of Chat data needed to render a Chat in the UI */
export type MinimalChatData = Prisma.ChatGetPayload<{
  include: {
    chatBotMemberships: {
      select: {
        botId: true;
      };
    };
    chatUserMemberships: {
      select: {
        isStarred: true;
      };
    };
    _count: {
      select: {
        LastSeenWorkflowExecution: true;
      };
    };
  };
}>;

/**
 * Represents a chat containing the message blocks.
 * This is what we actually get back from the server.
 */
export type FullChatMessage = Prisma.MessageGetPayload<{
  include: {
    userFeedbacks: true;
    blocks: true;
    _count: {
      select: { childMessages: true };
    };
    childMessages: {
      select: {
        id: true;
        bot: {
          select: {
            id: true;
          };
        };
        membership: {
          select: {
            user: {
              select: {
                id: true;
              };
            };
          };
        };
      };
    };
  };
}>;

/**
 * For each chat, this is stored at `queryKeyForChat`.
 */
export type ChatMessageQueryData = FullChatMessage[];
