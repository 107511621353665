import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { AblyProvider, ChannelProvider } from "ably/react";
import axios from "axios";
import { useSession } from "next-auth/react";
import { usePathname } from "next/navigation";

import { ChatCreatedEvent, MinimalChatData } from "@zapai/client-sdk";

import { useAblyClient } from "@/components/providers/ably-client-provider";
import { upsertChatToCache } from "@/hooks/helpers/client-cache-updates";
import { queryKeyForBotChats } from "@/hooks/use-bot-chats-query";
import { useAblyChannel } from "@/lib/ably-client";
import { AblyEventName, channelIdForUserMembership } from "@/lib/ably-events";
import { handleChatStreamingMessage } from "@/lib/chat-streaming/chat-streaming-message";

const handleChatCreatedEvent = async (
  queryClient: QueryClient,
  chatCreatedEvent: ChatCreatedEvent["payload"],
) => {
  const { botId, chatId } = chatCreatedEvent;

  try {
    const chat = (
      await axios.get<MinimalChatData>(`/api/conversations/${chatId}`)
    ).data;

    upsertChatToCache({
      chat,
      botId: chat.chatBotMemberships[0].botId,
      queryClient,
      // Only update the chats list cache that explicitly includes behavior runs
      chatsOnly: false,
    });
  } catch {
    // just refetch all the chats
    queryClient.invalidateQueries({
      queryKey: queryKeyForBotChats(botId),
    });
  }
};

const AblyMembershipChannelListener = ({
  channelName,
}: {
  channelName: string;
}) => {
  const queryClient = useQueryClient();
  const pathname = usePathname();

  useAblyChannel(
    channelName,
    AblyEventName.StreamingEvent,
    ({ data: streamEventData }) => {
      handleChatStreamingMessage(queryClient, streamEventData);
    },
  );

  useAblyChannel(
    channelName,
    AblyEventName.ChatCreated,
    ({ data: chatCreatedEvent }) => {
      const { payload } = chatCreatedEvent;
      // Only handle fetching the new chat if the user is on the corresponding bot page.
      // todo(corbin): perhaps we should move this event dispatch and handling to the
      // channelIdForBot(botId) channel instead of the membership channel?
      if (pathname.includes(payload.botId)) {
        handleChatCreatedEvent(queryClient, payload);
      }
    },
  );
  return null;
};

/**
 * This component is used to listen for events on the membership channel.
 *
 * It will listen for `ChatCreatedEvent` events and update the query client with the new chat.
 * - This is used to show background behavior executions
 *
 * It will also listen for `StreamingEvent` events and update the query client with the new chat message.
 * - This is used to asynchronously update the chat message in the UI after the event stream closed.
 */
export const AblyMembershipListener = () => {
  const ablyClient = useAblyClient();
  const { data: session } = useSession();
  const currentMembershipId = session?.user?.currentMembershipId;

  if (!currentMembershipId || !ablyClient) {
    return null;
  }

  const channelId = channelIdForUserMembership(currentMembershipId);

  return (
    <AblyProvider client={ablyClient}>
      <ChannelProvider channelName={channelId}>
        <AblyMembershipChannelListener channelName={channelId} />
      </ChannelProvider>
    </AblyProvider>
  );
};
