import { z } from 'zod';
import { Prisma } from '@prisma/client';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// JSON
//------------------------------------------------------

export type NullableJsonInput = Prisma.JsonValue | null | 'JsonNull' | 'DbNull' | Prisma.NullTypes.DbNull | Prisma.NullTypes.JsonNull;

export const transformJsonNull = (v?: NullableJsonInput) => {
  if (!v || v === 'DbNull') return Prisma.DbNull;
  if (v === 'JsonNull') return Prisma.JsonNull;
  return v;
};

export const JsonValueSchema: z.ZodType<Prisma.JsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.literal(null),
    z.record(z.lazy(() => JsonValueSchema.optional())),
    z.array(z.lazy(() => JsonValueSchema)),
  ])
);

export type JsonValueType = z.infer<typeof JsonValueSchema>;

export const NullableJsonValue = z
  .union([JsonValueSchema, z.literal('DbNull'), z.literal('JsonNull')])
  .nullable()
  .transform((v) => transformJsonNull(v));

export type NullableJsonValueType = z.infer<typeof NullableJsonValue>;

export const InputJsonValueSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.object({ toJSON: z.function(z.tuple([]), z.any()) }),
    z.record(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
    z.array(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
  ])
);

export type InputJsonValueType = z.infer<typeof InputJsonValueSchema>;


/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const ZapierAccountScalarFieldEnumSchema = z.enum(['id','name','logoUrl']);

export const RelationLoadStrategySchema = z.enum(['query','join']);

export const MembershipScalarFieldEnumSchema = z.enum(['id','role','zapierAccountId','userId','invitedName','invitedEmail']);

export const UserContextScalarFieldEnumSchema = z.enum(['id','content','userId','createdDT','updatedDT']);

export const UserScalarFieldEnumSchema = z.enum(['id','zapierUserId','zapierCustomUserId','name','firstName','lastName','email','emailVerified','image','code','role','createdDT','updatedDT','isStaff','timezone']);

export const BillingPlanScalarFieldEnumSchema = z.enum(['id','zapierAccountId','limits','periodStart','periodEnd','interval','createdDT','updatedDT']);

export const UsageHistoryScalarFieldEnumSchema = z.enum(['id','usageType','zapierAccountId','userId','createdDT','updatedDT','workflowId','actionId','toolName']);

export const AccountScalarFieldEnumSchema = z.enum(['id','userId','type','provider','providerAccountId','refresh_token','access_token','expires_at','token_type','scope','id_token','session_state','zapier_internal']);

export const SessionScalarFieldEnumSchema = z.enum(['id','sessionToken','userId','expires']);

export const VerificationTokenScalarFieldEnumSchema = z.enum(['identifier','token','expires']);

export const ChatScalarFieldEnumSchema = z.enum(['id','name','createdDT','updatedDT','archivedDT','pinnedDT','isDirectMessage','source','type','workflowId']);

export const MessageScalarFieldEnumSchema = z.enum(['id','chatId','membershipId','botId','parentMessageId','createdDT','updatedDT','sentDT','stateMachineExecutionId']);

export const MessageFeedbackScalarFieldEnumSchema = z.enum(['id','userId','messageId','createdDT','updatedDT','score','detail','triaged','hasEval','categoryId','triagingNotes','triagedById','triagedDT','discarded','tags','inputState','outputState']);

export const MessageModerationScalarFieldEnumSchema = z.enum(['id','messageId','flagged','categoryScores','categories','createdDT','updatedDT','inputState','outputState','flaggedContent']);

export const MessageFeedbackCategoryScalarFieldEnumSchema = z.enum(['id','group','category','name']);

export const MessageBlockScalarFieldEnumSchema = z.enum(['id','type','data','chatId','messageId','createdDT','updatedDT']);

export const BotScalarFieldEnumSchema = z.enum(['id','name','description','instructions','iconUrl','iconEmoji','agentConfigId','overridePersonaText','overrideLLMModelText','overrideOpenAIApiKeyId','overrideMaxResponseTokens','overrideCreativityRange','ownerMembershipId','connectedIntegrationIds','createdDT','updatedDT','metadata','fromTemplateId','fromBotId']);

export const BotShareScalarFieldEnumSchema = z.enum(['id','botId','creatorMembershipId','createdDT','updatedDT','removedDT']);

export const BotSlackWorkspaceScalarFieldEnumSchema = z.enum(['id','botId','slackTeamId','botToken','slackTeamName','slackTeamUrl']);

export const AppHomeViewScalarFieldEnumSchema = z.enum(['id','viewState','botId','slackUserId','slackUserEmail','slackUserRealName','slackUserDisplayName','botSlackWorkspaceId']);

export const BotExternalMessageScalarFieldEnumSchema = z.enum(['id','botId','externalId','source','data','createdDT','updatedDT']);

export const WorkflowTemplateScalarFieldEnumSchema = z.enum(['id','name','botTemplateId','data']);

export const ActionTemplateScalarFieldEnumSchema = z.enum(['id','description','displayName','actionKey','actionType','selectedApi','appNeedsAuth','needs','botTemplateId']);

export const DatasourceTemplateScalarFieldEnumSchema = z.enum(['id','sourceApi','name','docInfo','botTemplateId']);

export const BotTemplateGroupScalarFieldEnumSchema = z.enum(['id','name','description','category','createdDT','updatedDT','sharedInstructions']);

export const BotTemplateScalarFieldEnumSchema = z.enum(['id','name','description','instructions','createdDT','updatedDT','visibility','featured','schemaVersion','metadata','config','needsKnowledge','knowledgeSourceVersionlessApi','creatorUserId','templateGroupId','introMessage','configureMessage']);

export const AgentConfigScalarFieldEnumSchema = z.enum(['id','name','personaText','LLMModelText','maxResponseTokens','creativityRange','zapierAccountId']);

export const OpenAIApiKeyScalarFieldEnumSchema = z.enum(['id','keyName','encryptedApiKey','zapierAccountId']);

export const ChatUserMembershipScalarFieldEnumSchema = z.enum(['id','chatId','role','isStarred','membershipId','createdDT','updatedDT']);

export const ChatBotMembershipScalarFieldEnumSchema = z.enum(['id','chatId','botId','createdDT','updatedDT']);

export const ChatSessionScalarFieldEnumSchema = z.enum(['chatId','sessionId','createdDT','updatedDT','closedDT','data','sessionIdIncrement']);

export const DatasetScalarFieldEnumSchema = z.enum(['id','name','description','threeParSQLQuery','threeParCode','filter','count','sourceApp','data','fileKey','createdDT','updatedDT','chatId']);

export const ActionScalarFieldEnumSchema = z.enum(['id','name','displayName','description','selectedApi','actionKey','actionType','appNeedsAuth','userId','botId','workflowId','needs','auth','createdDT','updatedDT','lastUsedDT']);

export const ActionExecutionScalarFieldEnumSchema = z.enum(['id','actionId','botId','toolCallId','responseMessageId','createdDT','updatedDT']);

export const WorkflowScalarFieldEnumSchema = z.enum(['id','triggerSubscriptionId','subscriptionStatus','subscriptionStatusMessage','name','botId','userId','connectedIntegrationIds','triggerId','data','enabled','type','instructions','triggerSuggestionsIgnored','actionSuggestionsIgnored','wasTested','fromWorkflowTemplateId','createdDT','updatedDT']);

export const WorkflowExecutionScalarFieldEnumSchema = z.enum(['id','workflowId','type','status','details','createdDT','updatedDT']);

export const ToolExecutionScalarFieldEnumSchema = z.enum(['id','botId','toolCallId','responseMessageId','toolName','createdDT','updatedDT']);

export const AIQueryExecutionLogScalarFieldEnumSchema = z.enum(['id','chatId','botId','userId','responseMessageId','config','query','planner','steps','results','createdDT','updatedDT']);

export const StateMachineEventScalarFieldEnumSchema = z.enum(['id','stateMachineExecutionId','eventDT','eventType','stepName','state','extra']);

export const LastSeenWorkflowExecutionScalarFieldEnumSchema = z.enum(['id','userId','chatId','workflowExecutionId','createdDT','updatedDT']);

export const TokenConsumptionScalarFieldEnumSchema = z.enum(['id','userId','modelName','tokensUsed','direction','workflowExecutionId','isEval','responseMessageId']);

export const ExternalServiceJwtScalarFieldEnumSchema = z.enum(['id','jwt','userId','zapierAccountId','audience','expiresAt','createdDT','updatedDT']);

export const BotMemoryScalarFieldEnumSchema = z.enum(['id','ownerMembershipId','botId','workflowId','memory','memoryScope','label','createdDT','updatedDT']);

export const ThrottlingPolicyDefaultScalarFieldEnumSchema = z.enum(['id','domain','asyncWindowStart','syncWindowStart','asyncWindowDurationSec','syncWindowDurationSec','asyncMaxMessages','syncMaxMessages']);

export const ThrottlingPolicyScalarFieldEnumSchema = z.enum(['id','userId','asyncWindowStart','syncWindowStart','asyncWindowDurationSec','syncWindowDurationSec','asyncMaxMessages','syncMaxMessages','syncMessageCount','asyncMessageCount','createdDT','updatedDT']);

export const TermsOfServiceAcceptanceScalarFieldEnumSchema = z.enum(['id','userId','zapierAccountId','createdDT','text','consented']);

export const DataMigrationScalarFieldEnumSchema = z.enum(['name','status','metadata','createdAt','updatedAt']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const JsonNullValueInputSchema = z.enum(['JsonNull',]).transform((value) => (value === 'JsonNull' ? Prisma.JsonNull : value));

export const NullableJsonNullValueInputSchema = z.enum(['DbNull','JsonNull',]).transform((value) => value === 'JsonNull' ? Prisma.JsonNull : value === 'DbNull' ? Prisma.DbNull : value);

export const QueryModeSchema = z.enum(['default','insensitive']);

export const NullsOrderSchema = z.enum(['first','last']);

export const JsonNullValueFilterSchema = z.enum(['DbNull','JsonNull','AnyNull',]).transform((value) => value === 'JsonNull' ? Prisma.JsonNull : value === 'DbNull' ? Prisma.JsonNull : value === 'AnyNull' ? Prisma.AnyNull : value);

export const ChatSourceSchema = z.enum(['WEB','SLACK','EXTENSION']);

export type ChatSourceType = `${z.infer<typeof ChatSourceSchema>}`

export const MessageFeedbackCategoryGroupSchema = z.enum(['TOOL_LEVEL','WORKFLOW_LEVEL','SYSTEM_LEVEL','USER_LEVEL']);

export type MessageFeedbackCategoryGroupType = `${z.infer<typeof MessageFeedbackCategoryGroupSchema>}`

export const TemplateVisibilitySchema = z.enum(['public','unlisted','private']);

export type TemplateVisibilityType = `${z.infer<typeof TemplateVisibilitySchema>}`

export const DataMigrationStatusSchema = z.enum(['IN_PROGRESS','SUCCESS','FAILED']);

export type DataMigrationStatusType = `${z.infer<typeof DataMigrationStatusSchema>}`

export const MemoryScopeSchema = z.enum(['USER','BOT','WORKFLOW']);

export type MemoryScopeType = `${z.infer<typeof MemoryScopeSchema>}`

export const TokenDirectionSchema = z.enum(['INPUT','OUTPUT']);

export type TokenDirectionType = `${z.infer<typeof TokenDirectionSchema>}`

export const StateMachineEventTypeSchema = z.enum(['MachineStart','StateEntry','StateExit','Transition','ActionExecution','ServiceStart','ServiceEnd','ActivityStart','ActivityStop','Done','MachineStop','Error']);

export type StateMachineEventTypeType = `${z.infer<typeof StateMachineEventTypeSchema>}`

export const WorkflowTypeSchema = z.enum(['BOT_DIRECT_MESSAGE','TRIGGER']);

export type WorkflowTypeType = `${z.infer<typeof WorkflowTypeSchema>}`

export const UsageTypeSchema = z.enum(['BackgroundBehaviorExecution','BotDMBehaviorExecution','InstantActionExecution','InstantToolExecution','BehaviorToolExecution','BehaviorActionExecution','ChromeExtensionUsage','ChromeExtensionBehaviorUsage']);

export type UsageTypeType = `${z.infer<typeof UsageTypeSchema>}`

export const WorkflowExecutionStatusSchema = z.enum(['IN_PROGRESS','SUCCESS','FAILED','CANCELLED','ACTION_REQUIRED']);

export type WorkflowExecutionStatusType = `${z.infer<typeof WorkflowExecutionStatusSchema>}`

export const MembershipRoleSchema = z.enum(['OWNER','ADMIN','USER']);

export type MembershipRoleType = `${z.infer<typeof MembershipRoleSchema>}`

export const GlobalRoleSchema = z.enum(['SUPERADMIN','CUSTOMER']);

export type GlobalRoleType = `${z.infer<typeof GlobalRoleSchema>}`

export const MessageBlockTypeSchema = z.enum(['InteractiveTestWorkflowEnded','BehaviorSuggestion','ChatProgress','ChatCompletion','CodeStream','DatasetEvent','ThreeParReadTable','ThreeParReadList','ThreeParReadEntity','ThreeParWriteEntity','Markdown','PlainText','Json','Interactive','Onboarding','WorkflowExecutionRun','WorkflowExecutionSummary','WorkflowExecutionReference','WorkflowTriggerEvent']);

export type MessageBlockTypeType = `${z.infer<typeof MessageBlockTypeSchema>}`

export const ChatMemberRoleSchema = z.enum(['OWNER','USER','GUEST']);

export type ChatMemberRoleType = `${z.infer<typeof ChatMemberRoleSchema>}`

export const WorkflowExecutionTypeSchema = z.enum(['UserTest','BotDM','Background']);

export type WorkflowExecutionTypeType = `${z.infer<typeof WorkflowExecutionTypeSchema>}`

export const SubscriptionStatusTypeSchema = z.enum(['Pending','Success','Error']);

export type SubscriptionStatusTypeType = `${z.infer<typeof SubscriptionStatusTypeSchema>}`

export const ChatTypeSchema = z.enum(['CHAT','WORKFLOW','WORKFLOW_TEST']);

export type ChatTypeType = `${z.infer<typeof ChatTypeSchema>}`

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// ZAPIER ACCOUNT SCHEMA
/////////////////////////////////////////

export const ZapierAccountSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  logoUrl: z.string().nullable(),
})

export type ZapierAccount = z.infer<typeof ZapierAccountSchema>

/////////////////////////////////////////
// MEMBERSHIP SCHEMA
/////////////////////////////////////////

export const MembershipSchema = z.object({
  role: MembershipRoleSchema,
  id: z.string().uuid(),
  zapierAccountId: z.number().int(),
  userId: z.string().nullable(),
  invitedName: z.string().nullable(),
  invitedEmail: z.string().nullable(),
})

export type Membership = z.infer<typeof MembershipSchema>

/////////////////////////////////////////
// USER CONTEXT SCHEMA
/////////////////////////////////////////

export const UserContextSchema = z.object({
  id: z.number().int(),
  content: JsonValueSchema,
  userId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type UserContext = z.infer<typeof UserContextSchema>

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  role: GlobalRoleSchema,
  id: z.string().uuid(),
  zapierUserId: z.number().int(),
  zapierCustomUserId: z.number().int(),
  name: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string().nullable(),
  emailVerified: z.coerce.date().nullable(),
  image: z.string().nullable(),
  code: z.string().nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  isStaff: z.boolean(),
  timezone: z.string().nullable(),
})

export type User = z.infer<typeof UserSchema>

/////////////////////////////////////////
// BILLING PLAN SCHEMA
/////////////////////////////////////////

export const BillingPlanSchema = z.object({
  id: z.string().uuid(),
  zapierAccountId: z.number().int(),
  limits: JsonValueSchema,
  periodStart: z.coerce.date(),
  periodEnd: z.coerce.date(),
  interval: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type BillingPlan = z.infer<typeof BillingPlanSchema>

/////////////////////////////////////////
// USAGE HISTORY SCHEMA
/////////////////////////////////////////

export const UsageHistorySchema = z.object({
  usageType: UsageTypeSchema,
  id: z.string().uuid(),
  zapierAccountId: z.number().int().nullable(),
  userId: z.string().nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  workflowId: z.string().nullable(),
  actionId: z.string().nullable(),
  toolName: z.string().nullable(),
})

export type UsageHistory = z.infer<typeof UsageHistorySchema>

/////////////////////////////////////////
// ACCOUNT SCHEMA
/////////////////////////////////////////

export const AccountSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().nullable(),
  access_token: z.string().nullable(),
  expires_at: z.number().int().nullable(),
  token_type: z.string().nullable(),
  scope: z.string().nullable(),
  id_token: z.string().nullable(),
  session_state: z.string().nullable(),
  zapier_internal: JsonValueSchema.nullable(),
})

export type Account = z.infer<typeof AccountSchema>

/////////////////////////////////////////
// SESSION SCHEMA
/////////////////////////////////////////

export const SessionSchema = z.object({
  id: z.string().uuid(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date(),
})

export type Session = z.infer<typeof SessionSchema>

/////////////////////////////////////////
// VERIFICATION TOKEN SCHEMA
/////////////////////////////////////////

export const VerificationTokenSchema = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date(),
})

export type VerificationToken = z.infer<typeof VerificationTokenSchema>

/////////////////////////////////////////
// CHAT SCHEMA
/////////////////////////////////////////

export const ChatSchema = z.object({
  source: ChatSourceSchema.nullable(),
  type: ChatTypeSchema,
  id: z.string().uuid(),
  name: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  archivedDT: z.coerce.date().nullable(),
  isDirectMessage: z.boolean(),
  workflowId: z.string().nullable(),
})

export type Chat = z.infer<typeof ChatSchema>

/////////////////////////////////////////
// MESSAGE SCHEMA
/////////////////////////////////////////

export const MessageSchema = z.object({
  id: z.string().uuid(),
  chatId: z.string(),
  membershipId: z.string().nullable(),
  botId: z.string().nullable(),
  parentMessageId: z.string().nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  sentDT: z.coerce.date().nullable(),
  stateMachineExecutionId: z.string().nullable(),
})

export type Message = z.infer<typeof MessageSchema>

/////////////////////////////////////////
// MESSAGE FEEDBACK SCHEMA
/////////////////////////////////////////

export const MessageFeedbackSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  messageId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  score: z.number().int(),
  detail: z.string().nullable(),
  triaged: z.boolean(),
  hasEval: z.boolean(),
  categoryId: z.string().nullable(),
  triagingNotes: z.string().nullable(),
  triagedById: z.string().nullable(),
  triagedDT: z.coerce.date().nullable(),
  discarded: z.boolean(),
  tags: z.string().array(),
  inputState: JsonValueSchema.nullable(),
  outputState: JsonValueSchema.nullable(),
})

export type MessageFeedback = z.infer<typeof MessageFeedbackSchema>

/////////////////////////////////////////
// MESSAGE MODERATION SCHEMA
/////////////////////////////////////////

export const MessageModerationSchema = z.object({
  id: z.string().uuid(),
  messageId: z.string(),
  flagged: z.boolean(),
  categoryScores: JsonValueSchema,
  categories: JsonValueSchema,
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  inputState: JsonValueSchema.nullable(),
  outputState: JsonValueSchema.nullable(),
  flaggedContent: z.string().nullable(),
})

export type MessageModeration = z.infer<typeof MessageModerationSchema>

/////////////////////////////////////////
// MESSAGE FEEDBACK CATEGORY SCHEMA
/////////////////////////////////////////

export const MessageFeedbackCategorySchema = z.object({
  group: MessageFeedbackCategoryGroupSchema,
  id: z.string().uuid(),
  category: z.string(),
  name: z.string(),
})

export type MessageFeedbackCategory = z.infer<typeof MessageFeedbackCategorySchema>

/////////////////////////////////////////
// MESSAGE BLOCK SCHEMA
/////////////////////////////////////////

export const MessageBlockSchema = z.object({
  type: MessageBlockTypeSchema,
  id: z.string().uuid(),
  data: JsonValueSchema.nullable(),
  chatId: z.string(),
  messageId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type MessageBlock = z.infer<typeof MessageBlockSchema>

/////////////////////////////////////////
// BOT SCHEMA
/////////////////////////////////////////

export const BotSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().nullable(),
  instructions: z.string().nullable(),
  iconUrl: z.string().nullable(),
  iconEmoji: z.string().nullable(),
  agentConfigId: z.string().nullable(),
  overridePersonaText: z.string().nullable(),
  overrideLLMModelText: z.string().nullable(),
  overrideOpenAIApiKeyId: z.string().nullable(),
  overrideMaxResponseTokens: z.number().int().nullable(),
  overrideCreativityRange: z.number().nullable(),
  ownerMembershipId: z.string().nullable(),
  connectedIntegrationIds: z.string().array(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  metadata: JsonValueSchema.nullable(),
  fromTemplateId: z.string().nullable(),
  fromBotId: z.string().nullable(),
})

export type Bot = z.infer<typeof BotSchema>

/////////////////////////////////////////
// BOT SHARE SCHEMA
/////////////////////////////////////////

export const BotShareSchema = z.object({
  id: z.string().uuid(),
  botId: z.string(),
  creatorMembershipId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  removedDT: z.coerce.date().nullable(),
})

export type BotShare = z.infer<typeof BotShareSchema>

/////////////////////////////////////////
// BOT SLACK WORKSPACE SCHEMA
/////////////////////////////////////////

export const BotSlackWorkspaceSchema = z.object({
  id: z.string().uuid(),
  botId: z.string(),
  slackTeamId: z.string(),
  botToken: z.string(),
  slackTeamName: z.string().nullable(),
  slackTeamUrl: z.string().nullable(),
})

export type BotSlackWorkspace = z.infer<typeof BotSlackWorkspaceSchema>

/////////////////////////////////////////
// APP HOME VIEW SCHEMA
/////////////////////////////////////////

export const AppHomeViewSchema = z.object({
  id: z.number().int(),
  viewState: JsonValueSchema,
  botId: z.string(),
  slackUserId: z.string(),
  slackUserEmail: z.string(),
  slackUserRealName: z.string(),
  slackUserDisplayName: z.string(),
  botSlackWorkspaceId: z.string(),
})

export type AppHomeView = z.infer<typeof AppHomeViewSchema>

/////////////////////////////////////////
// BOT EXTERNAL MESSAGE SCHEMA
/////////////////////////////////////////

export const BotExternalMessageSchema = z.object({
  id: z.string().uuid(),
  botId: z.string(),
  externalId: z.string(),
  source: z.string(),
  data: JsonValueSchema,
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type BotExternalMessage = z.infer<typeof BotExternalMessageSchema>

/////////////////////////////////////////
// WORKFLOW TEMPLATE SCHEMA
/////////////////////////////////////////

export const WorkflowTemplateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  botTemplateId: z.string(),
  data: JsonValueSchema,
})

export type WorkflowTemplate = z.infer<typeof WorkflowTemplateSchema>

/////////////////////////////////////////
// ACTION TEMPLATE SCHEMA
/////////////////////////////////////////

export const ActionTemplateSchema = z.object({
  id: z.string().uuid(),
  description: z.string(),
  displayName: z.string(),
  actionKey: z.string(),
  actionType: z.string(),
  selectedApi: z.string(),
  appNeedsAuth: z.boolean(),
  needs: JsonValueSchema.nullable(),
  botTemplateId: z.string(),
})

export type ActionTemplate = z.infer<typeof ActionTemplateSchema>

/////////////////////////////////////////
// DATASOURCE TEMPLATE SCHEMA
/////////////////////////////////////////

export const DatasourceTemplateSchema = z.object({
  id: z.string().uuid(),
  sourceApi: z.string(),
  name: z.string(),
  docInfo: JsonValueSchema,
  botTemplateId: z.string(),
})

export type DatasourceTemplate = z.infer<typeof DatasourceTemplateSchema>

/////////////////////////////////////////
// BOT TEMPLATE GROUP SCHEMA
/////////////////////////////////////////

export const BotTemplateGroupSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string(),
  category: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  sharedInstructions: z.string(),
})

export type BotTemplateGroup = z.infer<typeof BotTemplateGroupSchema>

/////////////////////////////////////////
// BOT TEMPLATE SCHEMA
/////////////////////////////////////////

export const BotTemplateSchema = z.object({
  visibility: TemplateVisibilitySchema,
  id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  instructions: z.string().nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  featured: z.boolean(),
  schemaVersion: z.string(),
  metadata: JsonValueSchema.nullable(),
  config: JsonValueSchema.nullable(),
  needsKnowledge: z.boolean(),
  knowledgeSourceVersionlessApi: z.string().nullable(),
  creatorUserId: z.string().nullable(),
  templateGroupId: z.string().nullable(),
  configureMessage: z.string().nullable(),
})

export type BotTemplate = z.infer<typeof BotTemplateSchema>

/////////////////////////////////////////
// AGENT CONFIG SCHEMA
/////////////////////////////////////////

export const AgentConfigSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  personaText: z.string(),
  LLMModelText: z.string(),
  maxResponseTokens: z.number().int(),
  creativityRange: z.number(),
  zapierAccountId: z.number().int(),
})

export type AgentConfig = z.infer<typeof AgentConfigSchema>

/////////////////////////////////////////
// OPEN AI API KEY SCHEMA
/////////////////////////////////////////

export const OpenAIApiKeySchema = z.object({
  id: z.string().uuid(),
  keyName: z.string(),
  encryptedApiKey: z.string(),
  zapierAccountId: z.number().int(),
})

export type OpenAIApiKey = z.infer<typeof OpenAIApiKeySchema>

/////////////////////////////////////////
// CHAT USER MEMBERSHIP SCHEMA
/////////////////////////////////////////

export const ChatUserMembershipSchema = z.object({
  role: ChatMemberRoleSchema,
  id: z.string().uuid(),
  chatId: z.string(),
  isStarred: z.boolean(),
  membershipId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type ChatUserMembership = z.infer<typeof ChatUserMembershipSchema>

/////////////////////////////////////////
// CHAT BOT MEMBERSHIP SCHEMA
/////////////////////////////////////////

export const ChatBotMembershipSchema = z.object({
  id: z.string().uuid(),
  chatId: z.string(),
  botId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type ChatBotMembership = z.infer<typeof ChatBotMembershipSchema>

/////////////////////////////////////////
// CHAT SESSION SCHEMA
/////////////////////////////////////////

export const ChatSessionSchema = z.object({
  chatId: z.string(),
  sessionId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  closedDT: z.coerce.date().nullable(),
  data: JsonValueSchema,
  sessionIdIncrement: z.number().int(),
})

export type ChatSession = z.infer<typeof ChatSessionSchema>

/////////////////////////////////////////
// DATASET SCHEMA
/////////////////////////////////////////

export const DatasetSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string(),
  threeParSQLQuery: z.string().nullable(),
  threeParCode: z.string().nullable(),
  filter: JsonValueSchema.nullable(),
  count: z.number().int().nullable(),
  sourceApp: z.string().nullable(),
  data: JsonValueSchema.nullable(),
  fileKey: z.string().nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  chatId: z.string().nullable(),
})

export type Dataset = z.infer<typeof DatasetSchema>

/////////////////////////////////////////
// ACTION SCHEMA
/////////////////////////////////////////

export const ActionSchema = z.object({
  id: z.string().uuid(),
  name: z.string().nullable(),
  displayName: z.string(),
  description: z.string(),
  selectedApi: z.string(),
  actionKey: z.string(),
  actionType: z.string(),
  appNeedsAuth: z.boolean(),
  userId: z.string(),
  botId: z.string(),
  workflowId: z.string().nullable(),
  needs: JsonValueSchema.nullable(),
  auth: JsonValueSchema.nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
  lastUsedDT: z.coerce.date().nullable(),
})

export type Action = z.infer<typeof ActionSchema>

/////////////////////////////////////////
// ACTION EXECUTION SCHEMA
/////////////////////////////////////////

export const ActionExecutionSchema = z.object({
  id: z.string().uuid(),
  actionId: z.string(),
  botId: z.string(),
  toolCallId: z.string(),
  responseMessageId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type ActionExecution = z.infer<typeof ActionExecutionSchema>

/////////////////////////////////////////
// WORKFLOW SCHEMA
/////////////////////////////////////////

export const WorkflowSchema = z.object({
  subscriptionStatus: SubscriptionStatusTypeSchema.nullable(),
  type: WorkflowTypeSchema,
  id: z.string().uuid(),
  triggerSubscriptionId: z.string().nullable(),
  subscriptionStatusMessage: z.string().nullable(),
  name: z.string(),
  botId: z.string(),
  userId: z.string(),
  connectedIntegrationIds: z.string().array(),
  triggerId: z.string().nullable(),
  data: JsonValueSchema,
  enabled: z.boolean(),
  instructions: z.string(),
  triggerSuggestionsIgnored: z.boolean(),
  actionSuggestionsIgnored: z.boolean(),
  wasTested: z.boolean(),
  fromWorkflowTemplateId: z.string().nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type Workflow = z.infer<typeof WorkflowSchema>

/////////////////////////////////////////
// WORKFLOW EXECUTION SCHEMA
/////////////////////////////////////////

export const WorkflowExecutionSchema = z.object({
  type: WorkflowExecutionTypeSchema,
  status: WorkflowExecutionStatusSchema,
  id: z.string().uuid(),
  workflowId: z.string(),
  details: JsonValueSchema.nullable(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type WorkflowExecution = z.infer<typeof WorkflowExecutionSchema>

/////////////////////////////////////////
// TOOL EXECUTION SCHEMA
/////////////////////////////////////////

export const ToolExecutionSchema = z.object({
  id: z.string().uuid(),
  botId: z.string(),
  toolCallId: z.string(),
  responseMessageId: z.string(),
  toolName: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type ToolExecution = z.infer<typeof ToolExecutionSchema>

/////////////////////////////////////////
// AI QUERY EXECUTION LOG SCHEMA
/////////////////////////////////////////

export const AIQueryExecutionLogSchema = z.object({
  id: z.string().uuid(),
  chatId: z.string(),
  botId: z.string(),
  userId: z.string(),
  responseMessageId: z.string().nullable(),
  config: JsonValueSchema,
  query: JsonValueSchema,
  planner: JsonValueSchema,
  steps: JsonValueSchema.array(),
  results: JsonValueSchema,
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type AIQueryExecutionLog = z.infer<typeof AIQueryExecutionLogSchema>

/////////////////////////////////////////
// STATE MACHINE EVENT SCHEMA
/////////////////////////////////////////

export const StateMachineEventSchema = z.object({
  eventType: StateMachineEventTypeSchema,
  id: z.string().uuid(),
  stateMachineExecutionId: z.string(),
  eventDT: z.coerce.date(),
  stepName: z.string(),
  state: JsonValueSchema,
  extra: JsonValueSchema.nullable(),
})

export type StateMachineEvent = z.infer<typeof StateMachineEventSchema>

/////////////////////////////////////////
// LAST SEEN WORKFLOW EXECUTION SCHEMA
/////////////////////////////////////////

export const LastSeenWorkflowExecutionSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  chatId: z.string(),
  workflowExecutionId: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type LastSeenWorkflowExecution = z.infer<typeof LastSeenWorkflowExecutionSchema>

/////////////////////////////////////////
// TOKEN CONSUMPTION SCHEMA
/////////////////////////////////////////

export const TokenConsumptionSchema = z.object({
  direction: TokenDirectionSchema,
  id: z.string().uuid(),
  userId: z.string().nullable(),
  modelName: z.string(),
  tokensUsed: z.number().int(),
  workflowExecutionId: z.string().nullable(),
  isEval: z.boolean(),
  responseMessageId: z.string().nullable(),
})

export type TokenConsumption = z.infer<typeof TokenConsumptionSchema>

/////////////////////////////////////////
// EXTERNAL SERVICE JWT SCHEMA
/////////////////////////////////////////

export const ExternalServiceJwtSchema = z.object({
  id: z.string().uuid(),
  jwt: z.string(),
  userId: z.string().nullable(),
  zapierAccountId: z.number().int(),
  audience: z.string(),
  expiresAt: z.number().int(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type ExternalServiceJwt = z.infer<typeof ExternalServiceJwtSchema>

/////////////////////////////////////////
// BOT MEMORY SCHEMA
/////////////////////////////////////////

export const BotMemorySchema = z.object({
  memoryScope: MemoryScopeSchema,
  id: z.string().uuid(),
  ownerMembershipId: z.string(),
  botId: z.string().nullable(),
  workflowId: z.string().nullable(),
  memory: JsonValueSchema,
  label: z.string(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type BotMemory = z.infer<typeof BotMemorySchema>

/////////////////////////////////////////
// THROTTLING POLICY DEFAULT SCHEMA
/////////////////////////////////////////

export const ThrottlingPolicyDefaultSchema = z.object({
  id: z.string().uuid(),
  domain: z.string(),
  asyncWindowStart: z.coerce.date(),
  syncWindowStart: z.coerce.date(),
  asyncWindowDurationSec: z.number().int(),
  syncWindowDurationSec: z.number().int(),
  asyncMaxMessages: z.number().int(),
  syncMaxMessages: z.number().int(),
})

export type ThrottlingPolicyDefault = z.infer<typeof ThrottlingPolicyDefaultSchema>

/////////////////////////////////////////
// THROTTLING POLICY SCHEMA
/////////////////////////////////////////

export const ThrottlingPolicySchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  asyncWindowStart: z.coerce.date(),
  syncWindowStart: z.coerce.date(),
  asyncWindowDurationSec: z.number().int(),
  syncWindowDurationSec: z.number().int(),
  asyncMaxMessages: z.number().int(),
  syncMaxMessages: z.number().int(),
  syncMessageCount: z.number().int(),
  asyncMessageCount: z.number().int(),
  createdDT: z.coerce.date(),
  updatedDT: z.coerce.date(),
})

export type ThrottlingPolicy = z.infer<typeof ThrottlingPolicySchema>

/////////////////////////////////////////
// TERMS OF SERVICE ACCEPTANCE SCHEMA
/////////////////////////////////////////

export const TermsOfServiceAcceptanceSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  zapierAccountId: z.number().int(),
  createdDT: z.coerce.date(),
  text: z.string(),
  consented: z.boolean(),
})

export type TermsOfServiceAcceptance = z.infer<typeof TermsOfServiceAcceptanceSchema>

/////////////////////////////////////////
// DATA MIGRATION SCHEMA
/////////////////////////////////////////

export const DataMigrationSchema = z.object({
  status: DataMigrationStatusSchema,
  name: z.string(),
  metadata: JsonValueSchema.nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type DataMigration = z.infer<typeof DataMigrationSchema>
