import { ChatCompletionMessageParam } from "openai/resources";

export function completionContentToString(
  content: ChatCompletionMessageParam["content"],
) {
  if (!content) {
    return "";
  }

  if (Array.isArray(content)) {
    return content
      .map((c) => {
        switch (c.type) {
          case "text":
            return c.text;
          case "refusal":
            return c.refusal;
          case "image_url":
          default:
            return "";
        }
      })
      .join("");
  }

  return content;
}
