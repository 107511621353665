import { QueryClient } from "@tanstack/react-query";

import { ChatMessageBlockUpdatedEvent } from "@zapai/client-sdk";

import { updateOrAddMessageBlocksToMessage } from "@/hooks/helpers/client-cache-updates";

/**
 * Handle a `messageBlock.updated` event.
 *
 * This will come in multiple times in a row when streaming directly from the server,
 * or once after a message is fully complete via broadcasting.
 */
export const handleMessageBlockUpdatedEvent = (
  queryClient: QueryClient,
  data: ChatMessageBlockUpdatedEvent,
) => {
  const { chatId, messageId, parentMessageId } = data.payload;

  updateOrAddMessageBlocksToMessage({
    chatId,
    messageId,
    parentMessageId,
    queryClient,
    blocksToUpdate: [data.payload.block],
  });
};
