"use client";

import * as Toast from "@radix-ui/react-toast";
import * as Tooltip from "@radix-ui/react-tooltip";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import { SessionProvider } from "next-auth/react";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";

import { RawBlocksProvider } from "@/components/providers/raw-blocks-provider";
import { TourPointsProvider } from "@/components/providers/tour-points-provider";
import { ReplaceReturnType } from "@/helpers";

import { AblyClientProvider } from "./ably-client-provider";
import { AblyIntegrationListeners } from "./ably-integration-listeners";
import { AblyMembershipListener } from "./ably-membership-listener";

const handleError = (error: AxiosError) => {
  if (error?.response?.status === 401) {
    window.location.href = `/login?next=${window.location.href}`;
  }
};

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error: unknown) => {
          const axiosError = error as AxiosError;
          if (
            axiosError?.response?.status === 401 ||
            axiosError?.response?.status === 403 ||
            axiosError?.response?.status === 422
          ) {
            return false;
          }

          return failureCount <= 2;
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (!query.meta?.ignoreGlobalErrorHandler) {
          handleError(error as AxiosError);
        }
      },
    }),
  });
}

let browserQueryClient: QueryClient;

function getQueryClient() {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return makeQueryClient();
  }

  // Browser: make a new query client if we don't already have one
  // This is very important, so we don't re-make a new client if React
  // suspends during the initial render.
  if (!browserQueryClient) {
    browserQueryClient = makeQueryClient();
  }

  return browserQueryClient;
}

const SessionProviderWithNodeReturn = SessionProvider as ReplaceReturnType<
  typeof SessionProvider,
  ReactNode
>;

export function Providers({ children }: { children: any }) {
  const pathname = usePathname();

  const queryClient = getQueryClient();

  const isAdminPage = pathname.startsWith("/admin");
  const isExtension = pathname.includes("chrome-extension");

  const skipAbly = isAdminPage;

  return (
    <SessionProviderWithNodeReturn refetchOnWindowFocus={false}>
      <QueryClientProvider client={queryClient}>
        {!isAdminPage && !isExtension && (
          <ReactQueryDevtools buttonPosition="bottom-right" />
        )}
        <AblyClientProvider skipAbly={skipAbly}>
          <AblyIntegrationListeners />
          <AblyMembershipListener />
          <RawBlocksProvider>
            <Toast.Provider>
              <Tooltip.Provider delayDuration={300}>
                <TourPointsProvider>{children}</TourPointsProvider>
                <Toast.Viewport className="flex flex-col gap-1 absolute bottom-4 right-4 z-20 w-full max-w-[340px]" />
              </Tooltip.Provider>
            </Toast.Provider>
          </RawBlocksProvider>
        </AblyClientProvider>
      </QueryClientProvider>
    </SessionProviderWithNodeReturn>
  );
}

export default Providers;
