import { createContext } from "react";

interface RawBlocksContext {
  showRawBlocks: boolean;
  setShowRawBlocks: (showRawBlocks: boolean) => void;
}

export const RawBlocksContext = createContext<RawBlocksContext>({
  showRawBlocks: false,
  setShowRawBlocks: () => {},
});
