import type { Prisma } from "@prisma/client";

export interface ZapierEntitlementsResponse {
  limits: {
    polling_interval: number;
    throttle_multiplier: number;
    zap_limit: number;
    zap_length: number;
    zap_branch_depth_limit: number;
    node_branch_limit: number;
    action_limit: number;
    premium_apps: any[];
    block_and_release: number;
    autoreplay_errored_tasks: boolean;
    member_limit: number;
    is_super_admin_enabled: boolean;
    version_history_days: number;
    version_rollback_days: number;
    zapier_app_tasks_are_free: boolean;
    is_custom_notifications_enabled: boolean;
    is_alerts_center_enabled: boolean;
    audit_log_access_limit_months: number;
    is_static_ip_eligible: boolean;
    add_ons: string[];
    pay_per_task_enabled: boolean;
    is_scim_eligible: boolean;
    is_domain_capture_eligible: boolean;
    is_saml_eligible: boolean;
    is_team_enabled: boolean;
    is_teams_enabled: boolean;
    is_app_control_enabled: boolean;
    is_sharing_enabled: boolean;
    is_domain_insights_enabled: boolean;
    is_domain_verification_enabled: boolean;
    has_annual_task_limit: boolean;
    is_custom_error_handling_eligible: boolean;
    is_action_restrictions_enabled: boolean;
    is_analytics_dashboard_enabled: boolean;
    is_zap_custom_error_handling_enabled: boolean;
    is_version_comparison_enabled: boolean;
    is_approval_requests_enabled: boolean;
    allow_data_retention_config: boolean;
    premium_apps_limit: number;
    is_private_app_throttling_enabled: boolean;
    is_self_serve: boolean;

    // Billing limits
    central_activity_executions_limit?: number | "unlimited";
    central_is_web_browsing_enabled?: boolean;
    central_is_google_search_enabled?: boolean;
    central_agent_max_executions?: number;
  };
  plan_status: {
    id: number;
    name: string;
    descriptive_name: string;
    short_name: string;
    plan_type: string;
    interval: string;
    amount: number;
    metered_task_pricing: boolean;
    metered_pricing_tiers: string;
    period_start: string;
    period_end: string;
    is_team_account: boolean;
    teams_trial_end_date: string | null;
    is_company_account: boolean;
    companies_trial_end_date: string | null;
    is_enterprise_account: boolean;
    enterprise_trial_end_date: string | null;
    has_payment_source: boolean;
  };
  quotas: {
    tasks: {
      count: number;
      limit: "unlimited" | number;
      percent: number;
    };
    zaps: {
      count: number;
      limit: "unlimited" | number;
      percent: number;
    };
  };
}
export interface ZapierOAuthTokenResponse {
  access_token: string;
  refresh_token: string;
  expires_at: number;
  token_type: "Bearer";
  scope: string;
}

export interface ZapierWhoAmIResponse {
  is_logged_in: boolean;
  customuser_id: number;
  user_id: number;
  email: string;
  email_confirmed: boolean;
  timezone: string | null;
  roles: ZapierRole[];
}

interface AccessPolicy {
  id: number;
  account: number;
  access_level: string;
  verify_emails: boolean;
}

export interface PlanInfo extends Prisma.InputJsonObject {
  id: number;
  name: string;
  metered_task_pricing: boolean;
  interval: string;
  amount: number;
  allow_companies: boolean;
  allow_teams: boolean;
  allow_import_export: boolean;
  allow_professional?: boolean;
  has_annual_task_limit?: boolean;
}

interface Owner {
  id: number;
  name: string;
  email: string;
}

interface SurveysSubmitted {
  company_trial_onboarding: boolean;
  company_trial_exit: boolean;
}

export interface ZapierAccount {
  access_policy: AccessPolicy;
  allow_data_retention_config: boolean;
  allow_task_replay: boolean;
  autoreplay_errored_tasks_override: boolean;
  block_and_release: number;
  companies_trial_end_date: null | string;
  companies_trial_start_date: null | string;
  company_account_end_date: null | string;
  company_address: null | string;
  company_name: null | string;
  currency: string;
  customer_id: string;
  data_retention_days: number;
  disallowed_payment_methods: any[];
  enabled_trial_extensions: any[];
  first_known_upgrade_date: string;
  has_been_in_companies_trial: boolean;
  has_modern_pricing_plan: boolean;
  id: number;
  invoice_email: null | string;
  is_chat_eligible: boolean;
  is_company_account: boolean;
  is_controlled_by_identity_provider: boolean;
  is_google_domain_verification_supported: boolean;
  is_in_experts_mvp: boolean;
  is_premier_chat_eligible: boolean;
  is_saml_required: boolean;
  is_team_account: boolean;
  is_trialing_companies: boolean;
  is_trialing_teams: boolean;
  is_trialing: boolean;
  is_unlimited: boolean;
  last_charge_fail: null | string;
  last_plan_change_at: string;
  logo_choice: string;
  logo_url: string;
  name: string;
  offer_returning_trial_extension: boolean;
  owner: Owner;
  paid_manually_for_next_period: boolean;
  paying: boolean;
  pays_manually: false;
  period_end: string;
  period_start: string;
  plan_group_id: number;
  plan_info: PlanInfo;
  polling_interval: number;
  send_invoice_email: null | boolean;
  surveys_submitted: SurveysSubmitted;
  switches: Record<string, any>;
  team_account_end_date: null | string;
  teams_trial_end_date: null | string;
  trial_auto_upgrade: boolean;
  trial_end_date: null | string;
  active_offer_id: null | number;
  pending_offer_id: null | number;
  promotion_plan_group_id: null | number;
  show_task_upgrade_nudge: boolean;
  task_buffer_override: null | any;
  teams_trial_auto_upgrade: boolean;
  companies_trial_auto_upgrade: boolean;
  created_at: string;
  has_overdue_invoice: boolean;
}

export interface ZapierAccountsResponse {
  results: ZapierAccount[];
}

interface HasSeenNotifications {
  blog_subscription: null | boolean;
  jobs_cta: null | boolean;
}

export interface ZapierRole {
  account_id: number;
  role: string;
}

export interface ZapierSessionResponse {
  id: number; // this is the customuser_id
  code: string;
  user_id: number;
  auto_provisioned: boolean;
  first_name: string;
  last_name: string;
  username: string;
  personas: string;
  user_generated_personas: string;
  last_login: string;
  last_password_reset_token: string;
  email: string;
  email_hash: string;
  email_confirmed: boolean;
  timezone: string;
  photo_url: string;
  has_seen_notifications: HasSeenNotifications;
  signup: string;
  since_signup: string;
  has_activated: boolean;
  enable_gz_creator: boolean;
  should_see_nps_survey: boolean;
  is_developer: boolean;
  is_expert: boolean;
  tos_agreement: boolean;
  should_renew_tos: boolean;
  is_gdpr_consented: boolean;
  disable_ssl_check: boolean;
  identity: number;
  summary_schedule: string;
  alert_triggers: string;
  alert_actions: string;
  is_staff: boolean;
  is_zt_reviewer: boolean;
  is_high_value: boolean;
  is_temporary: boolean;
  banner_message: string;
  enable_totp_2fa: boolean;
  viewed_help: Record<string, any>;
  show_editor_migration_messaging: boolean;
  switches: Record<string, any>;
  organizations: any[];
  primary_organization: null | any;
  has_active_zaps: boolean;
  has_google_sso: boolean;
  is_logged_in: boolean;
  has_signed_up: boolean;
  is_masquerade: boolean;
  current_account_id: number;
  sso_hint: string;
  referrer_selected_api: null | string;
  roles: ZapierRole[];
}

export enum AdminRole {
  OWNER = "owner",
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
}

export function isAdminRole(value: any): value is AdminRole {
  return Object.values(AdminRole).includes(value);
}
